import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import moment from 'moment'
import RobotoBold from '../../../../../assets/fonts/Roboto-Bold.ttf'
import { ComArticle, CommandeResponse } from '../../../../../models'
import { handleNewErrorDetail } from './func'
import { t } from 'i18next'
import { addBreakLine } from './PickingList'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
})

interface NonListProps {
  commande?: CommandeResponse
  nonList: ComArticle[]
  idWithOrder?: string
  isForceDluo?: boolean
}

const styles = StyleSheet.create({
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginHorizontal: 20,
  },
  bodyTable: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    fontSize: 8,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    fontSize: 8,
  },
  header: {
    borderTop: 'none',
  },
  bold: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  rowTableHeader: {
    // width: '10%',
    padding: 8,
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  rowTable: {
    // width: '8%',

    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
    borderStyle: 'solid',
    borderLeftWidth: 1,
    // borderTopWidth: 1,
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    // overflow: 'hidden',
    // borderBottom: '1px solid black'
  },
  rowTableNoSpace: {
    width: 40,
    padding: 8,
    paddingRight: 0,
    borderStyle: 'solid',
    borderTopWidth: 1,
    textAlign: 'center',
    marginRight: -10,
  },
  rowTableLast: {
    width: '10%',
    padding: 8,
    paddingRight: 0,
    borderStyle: 'solid',
    borderRightWidth: 1,
    // borderTopWidth: 1,
  },
  header1: {
    fontWeight: 700,
    color: '#20458F',
    fontSize: 18,
    marginRight: 10,
  },
  header2Div: {
    paddingBottom: 10,
    borderBottom: '1px solid black',
    width: 206,
    marginLeft: 20,
    marginBottom: 10,
  },
  header2: {
    fontSize: 10,
    marginTop: 10,
  },
  header2Padding: {
    fontSize: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  header3: {
    color: '#20458F',
    fontSize: 18,
  },
  row1: {
    borderBottom: '1px solid #B7B7B7',
    marginBottom: 10,
    padding: 10,
  },
  cell: {
    borderLeft: '1px solid black',
  },
  lastLine: {
    borderBottom: '1px solid black',
  },
})

function toDluo(dluo: number | string | undefined) {
  if (!dluo) return '-'
  if (typeof dluo === 'number')
    return moment(Number(dluo) * 1000).format(t('date-format'))
  return dluo
}

const NonMiseList = (props: NonListProps) => {
  const { commande, nonList } = props
  return (
    <Document>
      <Page orientation="landscape">
        <View>
          <View style={[styles.row, styles.row1]}>
            <Text style={styles.header1}>
              Rapport de non mise en préparation
            </Text>
            <Text style={styles.header3}>(n°{props?.idWithOrder})</Text>
          </View>

          <View style={styles.header2Div}>
            <Text style={styles.header2}>
              RAPPORT DE NON MISE EN PREPARATION
            </Text>
          </View>
          <Text style={styles.header2Padding}>
            Forçage DLUO : {props?.isForceDluo ? 'Oui' : 'Non'}
          </Text>
        </View>
        <View style={styles.table}>
          <View>
            <View style={{ height: 1, backgroundColor: 'black' }}></View>
            <View style={[styles.row, styles.header]}>
              <Text style={[styles.rowTable, { width: 200 }]}>N° B . L .</Text>
              <Text style={[styles.rowTable, { width: 200 }]}>REFERENCE</Text>
              <Text style={[styles.rowTable, { width: 200 }]}>LOT</Text>
              <Text style={[styles.rowTable, { width: 250 }]}>SSCC</Text>
              <Text style={[styles.rowTable, { width: 200 }]}>D.L.U.O</Text>
              <Text
                style={[styles.rowTableLast, styles.rowTable, { width: 500 }]}
              >
                MOTIF DE NON PREPARATION
              </Text>
            </View>
            <View style={{ height: 1, backgroundColor: 'black' }}></View>
          </View>

          {nonList.length > 0 &&
            nonList.map((el, index) => {
              return (
                <View>
                  <View
                    key={index}
                    style={[
                      styles.bodyTable,
                      index === (commande?.com_article?.length || 0) - 1
                        ? { borderBottomWidth: 1 }
                        : {},
                    ]}
                    wrap={false}
                  >
                    <Text style={[styles.rowTable, { width: 200 }]}>
                      <Text>{commande?.nom || 'xxxx'}</Text>
                    </Text>
                    <Text style={[styles.rowTable, { width: 200 }]}>
                      <Text>{el.reference_number}</Text>
                    </Text>
                    <Text
                      style={[
                        styles.rowTable,
                        el.is_manual_lot ? styles.bold : {},
                        { width: 200 },
                      ]}
                    >
                      {addBreakLine(el.lot || '-', 12)}
                    </Text>
                    <Text
                      style={[
                        styles.rowTable,
                        { width: 250 },
                        el.is_manual_sscc ? styles.bold : {},
                      ]}
                    >
                      {el.sscc || ' '}
                    </Text>
                    <Text
                      style={[
                        styles.rowTable,
                        el.is_manual_dluo ? styles.bold : {},
                        { width: 200 },
                      ]}
                    >
                      {toDluo(el.dluo)}
                    </Text>
                    <Text
                      style={[styles.cell, styles.rowTableLast, { width: 500 }]}
                    >
                      {handleNewErrorDetail(el.error_details || '')
                        .join('')
                        .trim()
                        ? handleNewErrorDetail(el.error_details || '')
                        : 'Refusé par utilisateur ' +
                          localStorage.getItem('username')}
                    </Text>
                  </View>
                  <View style={{ height: 1, backgroundColor: 'black' }}></View>
                </View>
              )
            })}
          {nonList.length === 0 && (
            <View>
              <View style={[styles.bodyTable]} wrap={false}>
                <Text style={[styles.rowTable, { width: 200 }]}>
                  <Text></Text>
                </Text>
                <Text style={[styles.rowTable, { width: 200 }]}>
                  <Text></Text>
                </Text>
                <Text style={[styles.rowTable, { width: 200 }]}></Text>
                <Text style={[styles.rowTable, { width: 250 }]}></Text>
                <Text style={[styles.rowTable, { width: 200 }]}></Text>
                <Text
                  style={[styles.cell, styles.rowTableLast, { width: 500 }]}
                ></Text>
              </View>
              <View style={{ height: 1, backgroundColor: 'black' }}></View>
            </View>
          )}
          {/* <View style={[styles.row, { borderBottom: '1px solid black', marginRight: 45 }]}></View> */}
        </View>
      </Page>
    </Document>
  )
}

export default NonMiseList
