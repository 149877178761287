import {
  Destinataire,
  LocationStock,
  StockType,
  Transporteur,
  Adresses,
  Contact,
  FileResponse,
  ResponseGenerator,
  ReferenceProduct,
  Metadata,
} from '.'
import { StatusCommande } from '../enum/StatusCommande'
import { sourceType } from '../enum/sourceType'
import { ReactNode } from 'react'
import { CommandeScene } from '../enum/CommandeScene'
import { MissionType } from '../enum/MissionType'
import { EtaCommande } from '../enum/EtaCommande'

export enum InfoActionType {
  REAPPRO = 'REAPPRO',
  REAPPRO_MISSION = 'REAPPRO_MISSION',
  PICKING = 'PICKING',
}

export interface Commande {
  id?: string
  active_lot?: boolean
  chargement_at?: number
  client_code_nom: string
  com_article: ComArticle[]
  com_livraison?: ComCmt
  com_livraison_id?: string
  com_preparation?: ComCmt
  com_preparation_id?: string
  company_code_nom: string
  created_by?: string
  creater_id?: string
  des_addresse_id?: string
  des_contact_id?: string
  destinataire_id?: string
  imperatif?: boolean
  livraison_at?: number
  nom: string
  nom_client?: string
  photos?: number
  priorite?: number
  qte_total?: number
  relay_detail?: string
  sous_qte_total?: number
  tran_addresse_id?: string
  tran_contact_id?: string
  transporteur_id?: string
  warehouse_code_nom: string
  sscc?: CmdSscc
  destinataire?: Destinataire
  transporteur?: Transporteur
  status_code?: StatusCommande
  created_at?: number
  updated_at?: number
  modification_by?: string
  validation_at?: number
  validation_by?: string
  scene?: CommandeScene
  chrono?: string
  loading_validation_time?: number
  loading_validation_by?: string
  eta?: EtaCommande
}

export interface ComArticle {
  listLot?: string[]
  volumeCartonPicking?: number
  weightCartonPicking?: number
  mission_id?: string
  is_color?: boolean
  parent_id?: string
  parent_article_id?: string
  description?: string
  qte_confirmee?: number
  manquants?: number
  chrono?: string
  commande_id?: string
  comment?: string
  conditionement_id?: string
  created_at?: number
  created_by?: string
  creater_id?: string
  id?: string
  libelle?: string
  lot?: string
  dluo?: number
  modification_by?: string
  modificationer_id?: string
  quantite?: number
  reference_id?: string
  reference_number?: string
  status_code?: StatusCommande
  mouvement?: string
  type?: string
  eta?: EtaCommande
  statut?: string
  total_pieses: number
  sous_qte?: number
  update_at?: number
  validation_at?: string
  validation_by?: string
  validationer_id?: string
  loading_validation_at?: number
  loading_validation_by?: string
  sscc?: string
  prev_sscc?: string
  is_manual_lot?: boolean
  is_manual_sscc?: boolean
  is_manual_dluo?: boolean
  conditionement?: string
  conditionnement?: {
    att_enable: boolean
    couche?: number
    create_at?: number
    hauteur: number
    icone: string
    id: string
    largeur: number
    longueur: number
    niveau: number
    no_codebarre: string
    nom: string
    poids_add?: number
    poids_brut: number
    poids_net: number
    qte: number
    sous_qte?: number
    total_pieses?: number
    qte_variable?: boolean
    reference_id: string
    rotation: string
    support?: string
    type: number
    type_de_codebarre: string
    update_at?: number
    variante?: string
  }
  volume_qte?: number
  poids_qte?: number
  volume_sousqte?: number
  poids_sousqte?: number
  stock_id?: string
  stock?: StockType
  reference?: {
    alerte_stock: number
    blocage_entree: true
    blocage_sortie: true
    block_del: true
    client_code_nom: string
    conditionnement: [
      {
        att_enable: true
        couche: number
        create_at: number
        hauteur: number
        icone: string
        id: string
        largeur: number
        longueur: number
        niveau: number
        no_codebarre: string
        nom: string
        poids_add: number
        poids_brut: number
        poids_net: number
        qte: number
        qte_variable: true
        reference_id: string
        rotation: string
        support: string
        type: number
        type_de_codebarre: string
        update_at: number
        variante: string
      }
    ]
    create_at: number
    dlc: true
    dlc_value: number
    dluo: true
    dluo_value: number
    dlv: true
    dlv_value: number
    famille: string
    gestion: true
    icpe: [
      {
        code: string
        create_at: number
        description: string
        id: string
        qte_nv1: number
        qte_total_code: number
        qte_total_ref: number
        update_at: number
      }
    ]
    id: string
    langua_libelle: string
    libelle_court: string
    libelle_long: string
    marque: string
    matieresdangereuses: [
      {
        code: string
        create_at: number
        description: string
        id: string
        update_at: number
      }
    ]
    niveau_alerte: string
    nom: string
    origine: string
    photo: string
    qte_variable: true
    ref_charement: {
      comment: string
      create_at: number
      id: string
      reference_id: string
      update_at: number
    }
    ref_fournissers: [
      {
        att_enable: true
        blocage_entree: true
        block_del: true
        client_code_nom: string
        create_at: number
        fournisser_id: string
        id: string
        ref_nom: string
        reference_fournisser: string
        reference_id: string
        update_at: number
      }
    ]
    ref_livraison: {
      comment: string
      create_at: number
      id: string
      reference_id: string
      update_at: number
    }
    ref_reception: {
      comment: string
      create_at: number
      id: string
      reference_id: string
      update_at: number
    }
    sous_famille: string
    specificites: {
      centra_lisation: string
      code_regie: string
      couleur: string
      create_at: number
      degre: string
      fragilite: true
      froid: true
      id: string
      milesime: string
      nom_douaniere: true
      reference_id: string
      update_at: number
    }
    update_at: number
    variante: true
  }
  source?: sourceType
  pre_status?: StatusCommande
  error_details?: string

  //
  company_code_nom?: string
  client_code_nom?: string
  warehouse_code_nom?: string
  active_lot?: boolean
  commande_created_at?: number
  sscc_block?: string[]
  sscc_motif: string[]
  ruptures_check_lot?: number
  motif?: string
  updated_at?: number

  location_id?: string
  info_action?: InfoActionType
  cdn_level?: number
  conditionement_level?: number
  reappro_mission_id?: string

  //display column Statut
  reference_product?: ReferenceProduct
  produits?: string[]
}

export interface ComArticleValid {
  error_details?: string
  commande_id?: string
  comment?: string
  conditionement_id?: string
  create_at?: number
  created_by?: string
  creater_id?: string
  id?: string
  libelle?: string
  lot?: string
  dluo?: number
  modification_by?: string
  modificationer_id?: string
  quantite?: number
  reference_id?: string
  reference_number?: string
  status_code?: string
  statut?: string
  total_pieses: number
  sous_qte?: number
  update_at?: number
  validation_at?: string
  validation_by?: string
  validationer_id?: string
  sscc?: string
  conditionement?: string
  conditionnement?: {
    att_enable: boolean
    couche: number
    create_at: number
    hauteur: number
    icone: string
    id: string
    largeur: number
    longueur: number
    niveau: number
    no_codebarre: string
    nom: string
    poids_add: number
    poids_brut: number
    poids_net: number
    qte: number
    sous_qte: number
    total_pieses: number
    qte_variable: boolean
    reference_id: string
    rotation: string
    support: string
    type: number
    type_de_codebarre: string
    update_at: number
    variante: string
  }
  volume_qte?: number
  poids_qte?: number
  volume_sousqte?: number
  poids_sousqte?: number
  stock?: StockType
  reference?: {
    alerte_stock: number
    blocage_entree: true
    blocage_sortie: true
    block_del: true
    client_code_nom: string
    conditionnement: [
      {
        att_enable: true
        couche: number
        create_at: number
        hauteur: number
        icone: string
        id: string
        largeur: number
        longueur: number
        niveau: number
        no_codebarre: string
        nom: string
        poids_add: number
        poids_brut: number
        poids_net: number
        qte: number
        qte_variable: true
        reference_id: string
        rotation: string
        support: string
        type: number
        type_de_codebarre: string
        update_at: number
        variante: string
      }
    ]
    create_at: number
    dlc: true
    dlc_value: number
    dluo: true
    dluo_value: number
    dlv: true
    dlv_value: number
    famille: string
    gestion: true
    icpe: [
      {
        code: string
        create_at: number
        description: string
        id: string
        qte_nv1: number
        qte_total_code: number
        qte_total_ref: number
        update_at: number
      }
    ]
    id: string
    langua_libelle: string
    libelle_court: string
    libelle_long: string
    marque: string
    matieresdangereuses: [
      {
        code: string
        create_at: number
        description: string
        id: string
        update_at: number
      }
    ]
    niveau_alerte: string
    nom: string
    origine: string
    photo: string
    qte_variable: true
    ref_charement: {
      comment: string
      create_at: number
      id: string
      reference_id: string
      update_at: number
    }
    ref_fournissers: [
      {
        att_enable: true
        blocage_entree: true
        block_del: true
        client_code_nom: string
        create_at: number
        fournisser_id: string
        id: string
        ref_nom: string
        reference_fournisser: string
        reference_id: string
        update_at: number
      }
    ]
    ref_livraison: {
      comment: string
      create_at: number
      id: string
      reference_id: string
      update_at: number
    }
    ref_reception: {
      comment: string
      create_at: number
      id: string
      reference_id: string
      update_at: number
    }
    sous_famille: string
    specificites: {
      centra_lisation: string
      code_regie: string
      couleur: string
      create_at: number
      degre: string
      fragilite: true
      froid: true
      id: string
      milesime: string
      nom_douaniere: true
      reference_id: string
      update_at: number
    }
    update_at: number
    variante: true
  }
}

export interface ComArticleTable {
  commande_id?: string
  comment?: string
  cdn?: string
  create_at?: number
  created_by?: string
  creater_id?: string
  id?: string
  libelle?: string
  lot?: string
  dluo?: number
  modification_by?: string
  modificationer_id?: string
  qte?: number
  reference_id?: string
  reference?: string
  status_code?: string
  statut?: string
  total_piece?: number
  update_at?: number
  validation_at?: string
  validation_by?: string
  validationer_id?: string
  volume_qte?: number
  poids_qte?: number
  volume_sousqte?: number
  poids_sousqte?: number
  sous_qte?: number
  qte_confirmee?: number
  manquants?: number
  description?: string
  source?: string
  mission_id?: string
  is_color?: boolean
  company_code_nom?: string
  client_code_nom?: string
  warehouse_code_nom?: string
  active_lot?: boolean
  commande_created_at?: number
  sscc_block: string[]
  sscc_motif: string[]
  ruptures_check_lot?: number
  motif?: string
  parent_article_id?: string
  parent_id?: string
  conditionnement?: {
    att_enable: boolean
    couche?: number
    create_at?: number
    hauteur: number
    icone: string
    id: string
    largeur: number
    longueur: number
    niveau: number
    no_codebarre: string
    nom: string
    poids_add?: number
    poids_brut: number
    poids_net: number
    qte: number
    sous_qte?: number
    total_pieses?: number
    qte_variable?: boolean
    reference_id: string
    rotation: string
    support?: string
    type: number
    type_de_codebarre: string
    update_at?: number
    variante?: string
  }
  cdnOptions?: {
    value?: string
    label?: string
    extraValue?: string
  }
  info_action?: InfoActionType
  reappro_mission_id?: string
  conditionement_level?: number
  mouvement?: string
  eta?: string
  type?: string
}

export interface ComCmt {
  commande_id?: string
  comment?: string
  create_at?: number
  id?: string
  update_at?: number
}

export interface Mission {
  nom: string
  assignee_by?: string
  assignee_id?: string
  client_code_nom: string
  com_article?: ComArticle[]
  company_code_nom: string
  created_at: number
  updated_at?: number
  id: string
  manager_by?: string
  manager_id?: string
  modification_by: string
  modificationer_id: string
  parent_id: string
  priorite: number
  status_code: StatusCommande
  validation_by: string
  validation_time: number
  validationer_id: string
  warehouse_code_nom: string
  truck_number?: number
  mission_type?: MissionType
}

export interface ClientOb {
  nom?: string
  code?: string
  code_nom?: string
  fifo?: boolean
}

export interface CommandeResponse {
  id?: string
  active_lot?: boolean
  nom?: string
  force_dlou?: boolean
  chargement_at?: number
  livraison_at?: number
  client_code_nom?: string
  client_ob?: {
    code?: string
    code_nom?: string
    fefo?: false
    fifo?: true
    mois_dluo_activate?: boolean
    mois_dluo_input?: number
    nom?: string
  }
  company_code_nom?: string
  warehouse_code_nom?: string
  com_article?: ComArticle[]
  com_livraison?: ComCmt
  com_preparation?: ComCmt
  created_at?: number
  created_by?: string
  creater_id?: string
  des_addresse?: Adresses
  des_addresse_id?: string
  des_contact?: Contact
  des_contact_id?: string
  destinataire?: CmdDestinataire
  destinataire_id?: string
  imperatif?: boolean
  updated_at?: number
  modification_by?: string
  modificationer_id?: string
  nom_client?: string
  photos?: number
  priorite?: number
  qte_total?: number
  relay_detail?: string
  sous_qte_total?: number
  tran_addresse?: Adresses
  tran_addresse_id?: string
  tran_contact?: Contact
  tran_contact_id?: string
  transporteur?: CmdTransporteur
  transporteur_id?: string
  validation_at?: string
  validation_by?: string
  validationer_id?: string
  status_code?: StatusCommande
  com_file?: FileResponse[]
  mission?: Mission[]
  canceled_at?: number
  scene?: CommandeScene
  valid_times?: number
  info_action?: InfoActionType
  sscc_block?: string[]
  mouvement?: string
  type?: string
  eta?: string
}

export interface CommandeValidResponse {
  id?: string
  nom?: string
  chargement_at?: number
  livraison_at?: number
  client_code_nom?: string
  company_code_nom?: string
  warehouse_code_nom?: string
  com_article?: ComArticleValid[]
  com_livraison?: ComCmt
  com_preparation?: ComCmt
  create_at?: number
  created_by?: string
  creater_id?: string
  des_addresse?: Adresses
  des_addresse_id?: string
  des_contact?: Contact
  des_contact_id?: string
  destinataire?: CmdDestinataire
  destinataire_id?: string
  imperatif?: boolean
  update_at?: number
  modification_by?: string
  modificationer_id?: string
  nom_client?: string
  photos?: number
  priorite?: number
  qte_total?: number
  relay_detail?: string
  sous_qte_total?: number
  tran_addresse?: Adresses
  tran_addresse_id?: string
  tran_contact?: Contact
  tran_contact_id?: string
  transporteur?: CmdTransporteur
  transporteur_id?: string
  validation_at?: string
  validation_by?: string
  validationer_id?: string
  status_code?: StatusCommande
  com_file?: FileResponse[]
  info_action?: InfoActionType
}

export interface CmdConditionnement {
  att_enable: boolean
  couche: number
  create_at: number
  hauteur: number
  icone: string
  id: string
  largeur: number
  longueur: number
  niveau: number
  no_codebarre: string
  nom: string
  poids_add: number
  poids_brut: number
  poids_net: number
  qte: number
  qte_variable: boolean
  reference_id: string
  rotation: string
  support: string
  type: number
  type_de_codebarre: string
  update_at: number
  variante: string
}

export interface CmdIcpe {
  code: string
  create_at: number
  description: string
  id: string
  qte_nv1: number
  qte_total_code: number
  qte_total_ref: number
  update_at: number
}

export interface CmdMatieresdangereus {
  code: string
  create_at: number
  description: string
  id: string
  update_at: number
}

export interface CMdRefFournisser {
  att_enable: boolean
  blocage_entree: boolean
  block_del: boolean
  client_code_nom: string
  create_at: number
  fournisser_id: string
  id: string
  ref_nom: string
  reference_fournisser: string
  reference_id: string
  update_at: number
}

export interface CmdSpecificites {
  centra_lisation: string
  code_regie: string
  couleur: string
  create_at: number
  degre: string
  fragilite: boolean
  froid: boolean
  id: string
  milesime: string
  nom_douaniere: boolean
  reference_id: string
  update_at: number
}

export interface CmdStock {
  att_production: CmdAttProduction
  attendu_id: string
  client_code_nom: string
  company_code_nom: string
  create_at: number
  created_by: string
  creater_id: string
  cur_location_aisle: string
  cur_location_bay: string
  cur_location_code: string
  cur_location_id: string
  cur_zone_code: string
  cur_zone_id: string
  fabrication_at: number
  id: string
  liberation_at: number
  modification_at: number
  modification_by: string
  modifier_id: string
  pre_location_aisle: string
  pre_location_bay: string
  pre_location_code: string
  pre_location_id: string
  pre_zone_code: string
  pre_zone_id: string
  quantite: number
  reference_id: string
  reference_number: string
  sous_famille: string
  sous_qte: number
  status: string
  total_pieses: number
  update_at: number
  validation_at: number
  validation_by: string
  validationer_id: string
  warehouse_code_nom: string
}

export interface CmdAttProduction {
  att_bl: string
  attendu_id: string
  conditionement: string
  conditionement_id: string
  conditionnement: CmdConditionnement
  create_at: number
  dluo: number
  dluo_duration: number
  dluo_tmp: number
  id: string
  is_scan: boolean
  libelle: string
  lot: string
  lot_tmp: string
  poids_qte: number
  poids_sousqte: number
  quantite: number
  ref_block: boolean
  ref_change: boolean
  ref_fournisser_id: string
  ref_fournisseur_number: string
  reference_id: string
  reference_number: string
  sous_qte: number
  sscc: CmdSscc
  status: string
  total_pieses: number
  update_at: number
  validation_by: string
  validation_time: number
  validationer_id: string
  volume_qte: number
  volume_sousqte: number
}

export interface CmdSscc {
  att_production_id: string
  code_status: string
  commande_production_id: string
  create_at: number
  id: string
  num: string
  update_at: number
}

export interface CmdDestinataire {
  addresse: Adresses[]
  code: string
  contact: Contact[]
  create_at: number
  id: string
  nom: string
  update_at: number
}

export interface CmdTransporteur {
  addresse: Adresses[]
  code: string
  contact: Contact[]
  create_at: number
  id: string
  nom: string
  relay: boolean
  update_at: number
}

export interface CommandeRequest {
  entry: CommandeResponse
  details_delete?: {
    id: string[]
  }
  files_delete?: {
    id: string[]
  }
}

export interface CommandeInterface extends ResponseGenerator {
  data: {
    entry: CommandeResponse
  }
}

export interface CommandeUpdateResponse extends ResponseGenerator {
  data: {
    status?: string
    updated_at?: number
  }
}

export interface CommandeValidInterface extends ResponseGenerator {
  data: {
    entry: CommandeValidResponse
  }
}

export interface CommandeArrayInterface extends ResponseGenerator {
  data: {
    entry: Commande[]
    metadata: Metadata
  }
}

export type ReferenceInStockType = {
  att_production: {
    att_bl: string
    attendu_id: string
    conditionement: string
    conditionement_id: string
    conditionnement: {
      att_enable: boolean
      couche: number
      create_at: number
      hauteur: number
      icone: string
      id: string
      largeur: number
      longueur: number
      niveau: number
      no_codebarre: string
      nom: string
      poids_add: number
      poids_brut: number
      poids_net: number
      qte: number
      qte_variable: boolean
      reference_id: string
      rotation: string
      support: string
      type: number
      type_de_codebarre: string
      update_at: number
      variante: string
    }
    create_at: number
    dluo: number
    dluo_duration: number
    dluo_tmp: number
    id: string
    is_scan: boolean
    libelle: string
    lot: string
    lot_tmp: string
    poids_qte: number
    poids_sousqte: number
    quantite: number
    ref_block: boolean
    ref_change: boolean
    ref_fournisser_id: string
    ref_fournisseur_number: string
    reference_id: string
    reference_number: string
    sous_qte: number
    sscc: string
    status: string
    total_pieses: number
    update_at: number
    validation_by: string
    validation_time: number
    validationer_id: string
    volume_qte: number
    volume_sousqte: number
  }
  attendu_id: string
  client_code_nom: string
  company_code_nom: string
  conditionement_id: string
  conditionement_level: number
  conditionnement: {
    att_enable: boolean
    couche: number
    create_at: number
    hauteur: number
    icone: string
    id: string
    largeur: number
    longueur: number
    niveau: number
    no_codebarre: string
    nom: string
    poids_add: number
    poids_brut: number
    poids_net: number
    qte: number
    qte_variable: boolean
    reference_id: string
    rotation: string
    support: string
    type: number
    type_de_codebarre: string
    update_at: number
    variante: string
  }
  create_at: number
  created_by: string
  creater_id: string
  cur_height: number
  cur_level: number
  cur_location_aisle: string
  cur_location_bay: string
  cur_location_code: string
  cur_location_id: string
  cur_poids_brut: number
  cur_poids_net: number
  cur_volume: number
  cur_zone_code: string
  cur_zone_id: string
  eta: string
  fabrication_at: number
  id: string
  is_standard: boolean
  liberation_at: number
  liberation_by: string
  liberationer_id: string
  modification_at: number
  modification_by: string
  modifier_id: string
  mouvement: string
  number_of_free: number
  parent_id: string
  pre_level: number
  pre_location_aisle: string
  pre_location_bay: string
  pre_location_code: string
  pre_location_id: string
  pre_zone_code: string
  pre_zone_id: string
  quantite: number
  reappro_status: string
  reference_id: string
  reference_number: string
  sous_famille: string
  sous_qte: number
  status: string
  total_pieses: number
  type: string
  update_at: number
  validation_at: number
  validation_by: string
  validationer_id: string
  warehouse_code_nom: string
}

export interface ReferenceInCommande extends ResponseGenerator {
  data: {
    entry: ReferenceInStockType[]
    messageError: string
  }
}

export interface CommandeDataType {
  key: React.Key | string
  detail: ReactNode
  select: boolean
  id: string
  hasManquants?: boolean
  mouvement: string
  type?: string
  produit: string
  conditionnement: string
  qte: number
  sousqte: number
  totalPiece: number
  dluo: string
  statut?: string
  emplacement?: string
  bl: string
  commande: string
  commandeAttendu?: string
  fournisseur?: string
  current_location?: LocationStock
  previous_location?: LocationStock
  chrono: string
  chargement: string
  fifo?: string
  fabrication?: string
  liberation?: string
  creation: string
  modification: string
  validation: string
  created_by?: string
  modification_by?: string
  validateBy?: string
  children?: CommandeDataType[]
  volume_qte?: number
  poids_qte?: number
  volume_sousqte?: number
  poids_sousqte?: number
  destinataire: string
  destinataire_id?: string
  des_addresse_id?: string
  des_contact_id?: string
  transporteur: string
  transporteur_id?: string
  trans_addresse_id?: string
  trans_contact_id?: string
  lot?: string
  entre_time?: string
  status_code?: StatusCommande

  is_manual_sscc?: boolean
  isDetailLine?: boolean
  countProduit?: number
  countCdn?: number
  niveau?: number
  sscc?: string

  pre_statut?: string
  blocage_sortie?: boolean
  blocage_entree?: boolean
  isDisplayMainLineStatut?: boolean
  // list of produit contain in children's line
  produits?: string[]
  isPalletePicking?: boolean
  // list of lot contain in children's line
  listLot?: string[]
  volumeCartonPicking?: number
  weightCartonPicking?: number
  current_location_id?: string
  source?: sourceType
  manquants?: number
  stock?: StockType
  eta?: EtaCommande
  lineLevel: number
}

export interface CommandeEnvoyerMissionRequest {
  entry: {
    created_at?: number
    updated_at?: number
    parent_id: string
    modification_by: string
    modificationer_id: string
    validation_by: string
    validationer_id: string
    validation_time: number
    manager_id?: string
    manager_by?: string
    status_code?: string //status code of mission
    com_article?: ComArticle[]
    priorite: number
    company_code_nom: string
    warehouse_code_nom: string
    client_code_nom: string
    nom: string
    mission_type: MissionType
  }
  scene?: CommandeScene
  status_code?: string //status code of commande
}

export interface CommandeEnvoyerMissionNewPayload {
  active_lot?: boolean
  assignee_by?: string
  assignee_id?: string
  canceled_at?: number
  chargement_at?: number
  client_code_nom?: string
  com_file?: FileResponse[]
  company_code_nom?: string
  created_at?: number
  created_by?: string
  creater_id?: string
  des_addresse_id?: string
  des_contact_id?: string
  destinataire_id?: string
  eta?: string
  force_dlou?: boolean
  id?: string
  imperatif?: boolean
  livraison_at?: number
  modification_by?: string
  modificationer_id?: string
  mouvement?: string
  nom?: string
  nom_client?: string
  photos?: number
  priorite?: number
  qte_total?: number
  relay_detail?: string
  scene?: string
  sous_qte_total?: number
  status_code?: StatusCommande
  tran_addresse_id?: string
  tran_contact_id?: string
  transporteur_id?: string
  type?: string
  updated_at?: number
  valid_times?: number
  validation_at?: number
  validation_by?: string
  validationer_id?: string
  warehouse_code_nom?: string
  sscc_block?: string[]
}

export type ReplenishRequestType = {
  entry: {
    client_code_nom?: string
    commande_id?: string
    commande_nom?: string
    company_code_nom?: string
    priorite?: number
    ssccs?: Array<{
      is_manual_dluo: boolean
      is_manual_lot: boolean
      is_manual_sscc: boolean
      sscc: string
    }>
    warehouse_code_nom?: string
  }
}
