export enum StatusCommande {
  CreatedCommande = '60-0',
  ValidCommande = '60-1', // Manual_Sent on preparation
  SendOnMissionCommande = '60-2', // Manual_Sent on mission
  AssignedCommande = '60-3', // Manual_Assigned
  InProgressCommande = '60-4', // Manual_Inprogress
  PendingComande = '60-5',
  PickupCommande = '60-6', // pick up

  //App mission
  MissionUnassigned = '60-7',
  MissionAssigned = '60-8',
  MissionInprogress = '60-9',
  MissionPending = '60-10',
  MissionDone = '60-11',
  ArticleValCommande = '79-0',
  ArticlePickupCommande = '79-2',
}

export enum ReapproStatus {
  Created = '2K-0',
  Update = '2K-1',
}

export function isStatusCommandeHigher(
  status: StatusCommande,
  targetStatus: StatusCommande
) {
  return (
    Number(status.replace('-', '')) - Number(targetStatus.replace('-', '')) >= 0
  )
}

export function isStatusCommandeLower(
  status: StatusCommande,
  targetStatus: StatusCommande
) {
  return (
    Number(status.replace('-', '')) - Number(targetStatus.replace('-', '')) < 0
  )
}
