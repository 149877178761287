import moment from 'moment'
import { DataTableParam } from '../../features/stock/stockscreen/component/model'
import { ComArticle, ComArticleTable, FileResponse } from '../../models'
import Define from '../../constants/define'
import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import define from '../../constants/define'

export const convertDataTabletoAPI = (
  currentData: DataTableParam[][],
  isResend = false,
  fromCreate?: boolean
) => {
  let mapData: ComArticleTable[] = []
  const data = cloneDeep(currentData)
  for (let i = 0; i < data.length; i++) {
    const demo = formatDataToSendApi(data[i])
    mapData.push(demo)
  }
  //@ts-ignore
  let com_article: ComArticle[] = mapData.map(
    ({
      sous_qte,
      poids_qte,
      volume_sousqte,
      poids_sousqte,
      dluo,
      total_piece,
      qte,
      cdn,
      reference,
      volume_qte,
      qte_confirmee,
      manquants,
      description,
      source,
      is_color,
      client_code_nom,
      company_code_nom,
      warehouse_code_nom,
      ruptures_check_lot = 0,
      parent_article_id,
      parent_id,
      conditionnement,
      conditionement_level,
      lot,
      ...rest
    }) => {
      const result = {
        ...rest,
        dluo: moment(dluo, t('date-format')).unix() || 0,
        total_pieses: Number(total_piece) || 0,
        quantite: Number(qte) || 0,
        conditionement_id: cdn,
        reference_number: reference,
        volume_qte: Number(volume_qte) || 0,
        poids_qte: Number(poids_qte) || 0,
        volume_sousqte: Number(volume_sousqte) || 0,
        poids_sousqte: Number(poids_sousqte) || 0,
        sous_qte: Number(sous_qte) || 0,
        qte_confirmee: isResend ? Number(qte_confirmee) : undefined,
        manquants: isResend ? Number(manquants) : undefined,
        description: isResend ? description : undefined,
        source: isResend ? source : undefined,
        ruptures_check_lot: Number(ruptures_check_lot),
        parent_article_id: isResend ? parent_id : undefined,
        is_color: is_color || undefined,
        lot: lot?.trim(),
        client_code_nom: localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM),
        company_code_nom: localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM),
        warehouse_code_nom: localStorage.getItem(
          Define.CHOOSING_WAREHOUSE_CODENOM
        ),
        conditionement_level: Number(conditionement_level) || undefined,
      }

      return result
    }
  )
  if (fromCreate) return com_article.filter((item) => item.id !== '-')
  return com_article.filter((item) => item.id !== '-' && item.id)
}

function separateGroupedDetailData(
  currentData: DataTableParam[][],
  oldDataFromApi: ComArticle[]
) {
  const listOfFieldValueDifferent = {
    id: 'id',
    reappro_mission_id: 'reappro_mission_id',
    sscc: 'sscc',
    stock_id: 'stock_id',
    qte: 'quantite',
    qte_confirmee: 'qte_confirmee',
    total_piece: 'total_pieses',
    mission_id: 'mission_id',
  }
  // filter detail that has level not highest
  const removedHighestLevelData = currentData.filter(
    (item) =>
      item.find((i) => i.name === 'cdn')?.options?.[0]?.extraValue ===
        define.MAX_CDN_LEVEL || !item.find((i) => i.name === 'parent_id')?.value
  )
  // calculate detail that has level not highest from original api response to update some value equal to original data
  const notHighestLevelData =
    oldDataFromApi
      .filter(
        (i) =>
          i.conditionnement?.niveau === define.MAX_CDN_LEVEL - 1 &&
          i.parent_article_id
      )
      .map((i) => {
        let correspondCurrentData = cloneDeep(
          currentData.find(
            (item) =>
              item.find((i) => i.name === 'cdn')?.options?.[0]?.extraValue ===
                2 &&
              item.find((i) => i.name === 'parent_id')?.value ===
                i.parent_article_id &&
              item.find((i) => i.name === 'lot')?.value === (i.lot || '') &&
              item.find((i) => i.name === 'dluo')?.value ===
                (i.dluo
                  ? moment(Number(String(i.dluo)) * 1000).format(
                      t('date-format')
                    )
                  : '') &&
              item.find((i) => i.name === 'reference_id')?.value ===
                i.reference_id
          )
        )
        if (correspondCurrentData) {
          for (let [key, field] of Object.entries(listOfFieldValueDifferent)) {
            const fieldIndex = correspondCurrentData?.findIndex(
              (k) => k.name === key
            )
            if (
              (fieldIndex || fieldIndex === 0) &&
              correspondCurrentData?.[fieldIndex]
            ) {
              correspondCurrentData[fieldIndex] = {
                ...correspondCurrentData[fieldIndex],
                // @ts-ignore
                value: i?.[field]?.toString(),
              }
            }
          }
        }
        return correspondCurrentData || []
      }) || []
  return removedHighestLevelData.concat(notHighestLevelData)
}

// remove detail with level not highest and compensate by original detail from api to make sure not losing detail after group by lot, dluo, reference
export const convertSentPreparationDataTableToAPI = (
  currentData: DataTableParam[][],
  oldDataFromApi: ComArticle[]
) => {
  const dataSeparatedForNotHighestLevel = separateGroupedDetailData(
    currentData,
    oldDataFromApi
  )
  return convertDataTabletoAPI(dataSeparatedForNotHighestLevel, true, false)
}

function formatDataToSendApi(oldData: DataTableParam[]) {
  const data = cloneDeep(oldData)
  let demo: any = {}
  for (let j = 0; j < data.length; j++) {
    const name = data[j].name
    const value = data[j].value
    if (
      ![
        'dluoFake100years',
        'isBlock',
        'sscc_block',
        'sscc_motif',
        'stock_created_at',
        'manquants_search',
        'qte_confirmee_search',
        'motif_search',
        'searchResult',
        'isNewLine',
      ].includes(name)
    )
      demo[name] = value
    if (['sscc_block', 'sscc_motif'].includes(name))
      demo[name] = JSON.parse(value || '[]')
    if (['is_manual_sscc', 'is_manual_lot', 'is_manual_dluo'].includes(name)) {
      if (value === '0') demo[name] = false
      if (value === '1') demo[name] = true
    }
    if (name === 'is_color' && value === 'false') demo[name] = false
  }
  return demo
}

export function getNewFile(newFiles: FileResponse[], oldFiles: FileResponse[]) {
  if (newFiles.length === 0) {
    return undefined
  }
  let shouldAddFile: FileResponse[] = []
  newFiles.forEach((file) => {
    if (!oldFiles.find((i) => i.id === file.id)) {
      shouldAddFile.push(file)
    }
  })
  return shouldAddFile
}
