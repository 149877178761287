import { pdf } from '@react-pdf/renderer'
import { cloneDeep, findLastIndex, uniq } from 'lodash'
import moment from 'moment'
import { ClientRule } from '../../../../../enum/ClientRule'
import { CommandeScene } from '../../../../../enum/CommandeScene'
import {
  DocumentStatus,
  DocumentType,
} from '../../../../../enum/DocumentStatus'
import {
  ReapproStatus,
  StatusCommande,
} from '../../../../../enum/StatusCommande'
import { sourceType } from '../../../../../enum/sourceType'
import commandeApi from '../../../../../http/commandeApi'
import { EntryFind } from '../../../../../http/referenceApi'
import {
  ComArticle,
  CommandeInterface,
  CommandeResponse,
  FileResponse,
  InfoActionType,
  ReferenceInStockType,
} from '../../../../../models'
import { FileDescription } from '../../../../../models/Mission'
import {
  ddMMyyyyTo_date,
  getCurrentFiltersText,
  isGreatThan,
  isLessThan,
  yearTimestamps_100,
} from '../../../../../utils'
import {
  ActionParam,
  DataTableParam,
  ForceShowError,
  ForceShowWarning,
  OptionType,
  TitleParam,
} from '../../../stockscreen/component/model'
import NonMiseList from './NonMiseList'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { convertSentPreparationDataTableToAPI } from '../../../../../utils/commande/convertDataTabletoAPI'
import Define from '../../../../../constants/define'
import define from '../../../../../constants/define'
import { NameAndValue } from '../../../stockscreen/component/Wrapper'
import { uploadCartonPickList, uploadPalletePickList } from './file'
import { StatusStock } from '../../../../../enum/StatusStock'

export function getIndexes(name: string, data: DataTableParam[]) {
  return data.findIndex((item) => item.name === name)
}

export function getArticles(options: OptionType[], selected: string): number {
  let articles = 1
  const maxIndex = options.findIndex((item) => item.value === selected)
  for (let i = 0; i <= maxIndex; i++) {
    const qte = Number(options[i].extraValue)
    articles *= qte
  }

  return articles
}

export function isDuplicateInData(
  index: number,
  name: string,
  data: DataTableParam[][]
): boolean {
  const line = data[index]
  const currentProperty = line.find((item) => item.name === name)
  if (currentProperty) {
    for (let i = 0; i < data.length; i++) {
      const currentLine = data[i]
      const property = currentLine.find((item) => item.name === name)
      const parentId = getValueFromLine('parent_id', currentLine)
      if (i === index) continue
      if (parentId) continue
      if (!property) continue
      if (property?.value !== currentProperty.value) continue
      return true
    }
  }

  return false
}

export function isDuplicateInDataWithSSCC(
  index: number,
  name: string,
  data: DataTableParam[][]
): boolean {
  const line = data[index]
  const currentProperty = line.find((item) => item.name === name)
  if (currentProperty) {
    for (let i = 0; i < data.length; i++) {
      const currentLine = data[i]
      const property = currentLine.find((item) => item.name === name)
      const sscc = currentLine.find((item) => item.name === 'sscc')?.value
      if (i === index) continue
      if (!property) continue
      if (sscc) continue
      if (property?.value !== currentProperty.value) continue
      return true
    }
  }

  return false
}

export function isDuplicateInDataWithoutSSSCC(
  index: number,
  name: string,
  data: DataTableParam[][]
): boolean {
  const line = data[index]
  const currentProperty = line.find((item) => item.name === name)
  if (currentProperty) {
    for (let i = 0; i < data.length; i++) {
      const currentLine = data[i]
      const property = currentLine.find((item) => item.name === name)
      const sscc = currentLine.find((item) => item.name === 'sscc')?.value
      if (i === index) continue
      if (!property) continue
      if (sscc) continue
      if (property?.value !== currentProperty.value) continue
      return true
    }
  }

  return false
}

export function getIn4FromResultOfSSCC(result: ReferenceInStockType) {
  const ref: OptionType = {
    label: result.reference_number,
    value: result.reference_number,
    extraValue: result.reference_id,
  }
  const qte = result.sous_qte
  const cdn: OptionType = {
    label: result.conditionnement?.nom,
    value: result.conditionement_id,
  }

  const total_piece = result.total_pieses
  const lot = result.att_production?.lot
  const dluo = result.att_production?.dluo
  const conditionement_id = result.conditionement_id
  const libelle = result.att_production?.libelle
  const level = result.conditionement_level
  const quantite = result.quantite
  return {
    qte,
    cdn,
    total_piece,
    lot,
    dluo,
    ref,
    conditionement_id,
    libelle,
    level,
    quantite,
  }
}

export function selectDefaultReference(data: ReferenceInStockType[]) {
  const notHighestLevelItem = data.find(
    (ref) => ref.conditionement_level === Define.MAX_CDN_LEVEL - 1
  )
  if (
    notHighestLevelItem?.status === StatusStock.CREATE &&
    notHighestLevelItem.reappro_status === ReapproStatus.Created
  ) {
    return data.find((ref) => ref.conditionement_level === Define.MAX_CDN_LEVEL)
  } else {
    return notHighestLevelItem
  }
}

export function forceError(
  index: number,
  name: string,
  message: string,
  forceShowError: ForceShowError[][]
) {
  const indexOfProperty = forceShowError[index].findIndex(
    (item) => item.name === name
  )

  if (indexOfProperty !== -1)
    forceShowError[index][indexOfProperty].message = message
  else forceShowError[index].push({ name, message })

  return forceShowError
}

export function getValueFromData(
  index: number,
  name: string,
  data: DataTableParam[][]
) {
  return data[index].find((item) => item.name === name)?.value
}

function getLevelFromLine(line: DataTableParam[]) {
  const cdn = line.find((item) => item.name === 'cdn')
  const optionChoice = cdn?.options?.find((item) => item.value === cdn?.value)
  return optionChoice?.level
}

export function checkDupSSccCmdLines(index: number, data: DataTableParam[][]) {
  const currentLine = data[index]

  for (let i = 0; i < data.length; i++) {
    if (i === index) continue
    const parentId = getValueFromLine('parent_id', data[i])
    if (parentId) continue
    const sscc1 = getValueFromLine('sscc', currentLine) || ''
    const cdn1 = getLevelFromLine(currentLine) || ''

    const sscc2 = getValueFromLine('sscc', data[i]) || ''
    const cdn2 = getLevelFromLine(data[i]) || ''
    if (sscc1 && sscc2 && sscc1 === sscc2 && cdn1 && cdn2 && cdn1 === cdn2)
      return true
  }

  return false
}

export function checkDluoCmdLinesNotValid(
  index: number,
  data: DataTableParam[][]
) {
  const currentLine = data[index]

  for (let i = 0; i < data.length; i++) {
    if (i === index) continue
    const parentId = getValueFromLine('parent_id', data[i])
    if (parentId) continue
    const sscc1 = getValueFromLine('sscc', data[i]) || ''
    const ref1 = getValueFromLine('reference', data[i]) || ''
    const lot1 = getValueFromLine('lot', data[i]) || ''
    const dluo1 = getValueFromLine('dluo', data[i]) || ''
    const cdn1 = getLevelFromLine(data[i]) || ''

    const ref2 = getValueFromLine('reference', currentLine) || ''
    const lot2 = getValueFromLine('lot', currentLine) || ''
    const dluo2 = getValueFromLine('dluo', currentLine) || ''
    const cdn2 = getLevelFromLine(currentLine) || ''
    if (
      !sscc1 &&
      ref1 &&
      ref2 &&
      lot1 &&
      lot2 &&
      dluo1 &&
      dluo2 &&
      ref1 === ref2 &&
      lot1 === lot2 &&
      cdn1 === cdn2 &&
      dluo1 !== dluo2
    )
      return true
  }

  return false
}

export function checkDupEntriesCmdLines(
  index: number,
  data: DataTableParam[][]
) {
  const currentLine = data[index]
  for (let i = 0; i < data.length; i++) {
    if (i === index) continue
    const parentId = getValueFromLine('parent_id', data[i])
    if (parentId) continue
    const sscc1 = getValueFromLine('sscc', data[i]) || ''
    const ref1 = getValueFromLine('reference', data[i]) || ''
    const cdn1 = getLevelFromLine(data[i]) || ''
    const lot1 = getValueFromLine('lot', data[i]) || ''
    const dluo1 = getValueFromLine('dluo', data[i]) || ''

    const ref2 = getValueFromLine('reference', currentLine) || ''
    const cdn2 = getLevelFromLine(currentLine) || ''
    const lot2 = getValueFromLine('lot', currentLine) || ''
    const dluo2 = getValueFromLine('dluo', currentLine) || ''
    if (
      !sscc1 &&
      ref1 &&
      ref2 &&
      cdn1 &&
      cdn2 &&
      ref1 === ref2 &&
      cdn1 === cdn2 &&
      lot1 === lot2 &&
      (dluo1 === dluo2 ||
        (lot1 && !dluo1 && dluo2) ||
        (lot1 && dluo1 && !dluo2))
    )
      return true
  }

  return false
}

export function setActionsToReadyForEditAndDelete(actions: ActionParam[]) {
  return actions.map((item) => {
    item.isAllowDelete = true
    item.isAllowEdit = true
    item.isFillAllRequired = true
    item.isForceValid = true
    item.isValid = true
    return item
  })
}

export function setWarningForDluo(
  dluo: string,
  index: number,
  forceShowWarningClone: ForceShowWarning[][],
  setForceShowWarning: (
    value: React.SetStateAction<ForceShowWarning[][]>
  ) => void
) {
  if (
    dluo &&
    (dayjs().isAfter(dayjs(dluo, t('date-format')), 'date') ||
      dayjs().isSame(dayjs(dluo, t('date-format')), 'date'))
  ) {
    forceShowWarningClone[index].push({
      name: 'dluo',
      message: 'La DLUO est dépassée',
    })
  } else {
    forceShowWarningClone[index] = forceShowWarningClone[index].filter(
      (item) => item.name !== 'dluo'
    )
  }
  setForceShowWarning(forceShowWarningClone)
}

export function addOrReplaceRefId(line: DataTableParam[], id: string) {
  const refIndex = line.findIndex((item) => item.name === 'reference_id')
  if (refIndex === -1)
    line.push({
      name: 'reference_id',
      value: id,
      isHide: true,
    })
  else line[refIndex].value = id
  return line
}

function getSSCCText(
  item: ComArticle,
  isSearching?: boolean,
  childNotMaxLevelArticles?: ComArticle[]
) {
  if (
    (item.conditionnement?.niveau == Define.MAX_CDN_LEVEL - 1 ||
      item.conditionement_level === Define.MAX_CDN_LEVEL - 1) &&
    item.parent_article_id
  ) {
    switch (item.info_action) {
      case InfoActionType.REAPPRO:
        if (isSearching) {
          if (childNotMaxLevelArticles?.find((i) => i.sscc === item.sscc)) {
            return t('reappro_mission')
          }
        }
        return t('reappro')
      case InfoActionType.PICKING:
        return t('picking')
      case InfoActionType.REAPPRO_MISSION:
        return isSearching ? t('reappro_mission') : t('reappro')
      default:
        return t('picking')
    }
  }
  return item.sscc || ''
}

export function convertFromDetailDataOfApi(
  com_article: ComArticle[],
  isSearching?: boolean
): DataTableParam[][] {
  const result: DataTableParam[][] = []
  const listCurrentSumByLot: any[] = []

  com_article
    .sort((a, b) => {
      if (!a.parent_article_id && !b.parent_article_id) {
        // @ts-ignore
        if (a.created_at < b.created_at) {
          return -1
        } else {
          return 1
        }
      } else {
        const articleParentIdA = a.parent_article_id
        if (articleParentIdA) {
          return 1
        } else {
          return -1
        }
      }
    })
    .sort((a, b) => {
      const refA = a.parent_article_id || a.id || ''
      const refB = b.parent_article_id || b.id || ''
      if (refA < refB) {
        return 1
      } else if (refA > refB) {
        return -1
      } else {
        const articleParentIdA = a.parent_article_id
        if (articleParentIdA) {
          return 1
        } else {
          return -1
        }
      }
    })
    .forEach((element, index) => {
      const line: DataTableParam[] = []
      const {
        reference_id,
        reference_number,
        quantite: qte,
        total_pieses,
        conditionnement,
        volume_qte,
        volume_sousqte,
        poids_sousqte,
        poids_qte,
        sous_qte,
        parent_article_id,
        description,
        qte_confirmee,
        manquants,
        source,
        mission_id,
        is_color,
        created_at,
        error_details,
        sscc_block,
        sscc_motif,
        is_manual_dluo,
        is_manual_lot,
        is_manual_sscc,
        ruptures_check_lot = 0,
        commande_id,
        motif = '',
        conditionement,
        stock,
        location_id,
        info_action,
        conditionement_level,
        reappro_mission_id,
      } = element
      const locationId = element.location_id || ''
      const libelle = element?.libelle || ''
      const lot = element?.lot || ''
      const sscc = element?.sscc || ''
      const statut = element?.statut || ''
      const cdn = element?.conditionement_id
      const dluo = element?.dluo || ''
      const id = element?.id
      const parent = com_article.find((item) => item.id === parent_article_id)
      const ssccOfParent = parent?.sscc
      line.push({ name: 'id', value: id, isHide: true })
      line.push({ name: 'location_id', value: locationId, isHide: true })
      line.push({ name: 'reference_id', value: reference_id, isHide: true })
      line.push({ name: 'reference', value: reference_number })
      line.push({ name: 'libelle', value: libelle, disabled: true })
      line.push({ name: 'lot', value: lot || '' })
      line.push({
        name: 'dluo',
        value: dluo
          ? moment(Number(String(dluo)) * 1000).format(t('date-format'))
          : '',
      })
      line.push({ name: 'statut', value: statut })
      line.push({
        name: 'cdn',
        value: cdn,
        options: [
          {
            label: conditionnement?.nom || '',
            value: cdn,
            extraValue: conditionnement?.niveau,
            level: conditionement_level,
          },
        ],
      })
      const ssccDisplay = getSSCCText(
        element,
        isSearching,
        com_article?.filter(
          (item) =>
            item.parent_article_id &&
            (item.conditionnement?.niveau == Define.MAX_CDN_LEVEL - 1 ||
              item.conditionement_level === Define.MAX_CDN_LEVEL - 1) &&
            item.info_action === InfoActionType.REAPPRO_MISSION
        )
      )
      line.push({
        name: 'sscc',
        value: ssccDisplay,
      })
      line.push({ name: 'sous_qte', value: String(sous_qte) })
      line.push({ name: 'volume_sousqte', value: String(volume_sousqte) })
      line.push({ name: 'volume_qte', value: String(volume_qte) })
      line.push({ name: 'poids_qte', value: String(poids_qte) })
      line.push({ name: 'poids_sousqte', value: String(poids_sousqte) })
      line.push({
        name: 'parent_id',
        value: parent_article_id || '',
      })
      line.push({ name: 'poids_sousqte', value: String(poids_sousqte) })
      line.push({ name: 'description', value: String(description || '') })
      line.push({ name: 'source', value: source })
      line.push({
        name: 'manquants',
        value: String(manquants || '0'),
      })
      line.push({
        name: 'mission_id',
        value: mission_id,
      })
      line.push({
        name: 'is_color',
        // @ts-ignore
        value: is_color || false,
      })
      line.push({
        name: 'created_at',
        value: String(created_at),
        isHide: true,
      })
      line.push({
        name: 'dluoFake100years',
        value: String((created_at || 0) + yearTimestamps_100),
        isHide: true,
      })
      line.push({
        name: 'isBlock',
        value:
          is_color ||
          error_details?.includes('was block') ||
          error_details?.includes('blocked') ||
          (error_details?.includes('exist on commande with Num') &&
            !parent_article_id) ||
          (ssccOfParent && parent_article_id)
            ? '1'
            : '0',
        isHide: true,
      })
      line.push({
        name: 'sscc_block',
        value: JSON.stringify(sscc_block),
        isHide: true,
      })
      line.push({
        name: 'sscc_motif',
        value: JSON.stringify(sscc_motif),
        isHide: true,
      })
      line.push({
        name: 'is_manual_sscc',
        value: is_manual_sscc ? '1' : '0',
        isHide: true,
      })
      line.push({
        name: 'is_manual_lot',
        value: is_manual_lot ? '1' : '0',
        isHide: true,
      })
      line.push({
        name: 'is_manual_dluo',
        value: is_manual_dluo ? '1' : '0',
        isHide: true,
      })

      line.push({
        name: 'ruptures_check_lot',
        value: String(ruptures_check_lot),
        isHide: true,
      })
      line.push({
        name: 'commande_id',
        value: commande_id,
        isHide: true,
      })
      line.push({
        name: 'motif',
        value: motif,
      })

      line.push({
        name: 'stock_created_at',
        value: String(stock?.create_at),
      })

      line.push({
        name: 'info_action',
        value: info_action,
      })

      line.push({
        name: 'conditionement_level',
        value: String(conditionement_level),
      })

      line.push({
        name: 'reappro_mission_id',
        value: reappro_mission_id,
      })

      // Handle case if detail line's niveau is 2
      if (
        (conditionnement?.niveau == 2 || conditionement_level === 2) &&
        parent_article_id
      ) {
        // check if existed lot, main line id and action info in array
        const existedLotAndActionInfo = listCurrentSumByLot.find((i) => {
          const issccDisplay = getSSCCText(
            i,
            isSearching,
            com_article?.filter(
              (item) =>
                item.parent_article_id &&
                (item.conditionnement?.niveau == Define.MAX_CDN_LEVEL - 1 ||
                  item.conditionement_level === Define.MAX_CDN_LEVEL - 1) &&
                item.info_action === InfoActionType.REAPPRO_MISSION
            )
          )
          return (
            i.lot === lot &&
            i.parent_article_id === parent_article_id &&
            ((!info_action && !i.info_action) ||
              issccDisplay === ssccDisplay) &&
            i.dluo === dluo &&
            i.is_color === is_color
          )
        })
        // If not existed , add to detail line as usual
        if (!existedLotAndActionInfo) {
          listCurrentSumByLot.push({
            lot,
            parent_article_id,
            info_action,
            dluo,
            qte,
            total_pieses,
            qte_confirmee,
            is_color,
            index: result.length,
            sscc,
            conditionement_level,
            conditionnement,
          })
          line.push({
            name: 'qte',
            value: String(qte),
            disabled: Boolean(sscc),
          })
          line.push({
            name: 'total_piece',
            value: String(total_pieses),
            disabled: true,
          })
          line.push({
            name: 'qte_confirmee',
            value: String(qte_confirmee || '0'),
          })
        } else {
          // If existed, display sum of qte, total pieces , qte confirmee
          existedLotAndActionInfo.qte += qte
          existedLotAndActionInfo.total_pieses += total_pieses
          existedLotAndActionInfo.qte_confirmee += qte_confirmee
          line.push({
            name: 'qte',
            value: String(existedLotAndActionInfo.qte),
            disabled: true,
          })
          line.push({
            name: 'total_piece',
            value: String(existedLotAndActionInfo.total_pieses),
            disabled: true,
          })
          line.push({
            name: 'qte_confirmee',
            value: String(existedLotAndActionInfo.qte_confirmee || '0'),
          })
          result.splice(existedLotAndActionInfo.index, 1)
        }
      } else {
        line.push({
          name: 'qte',
          value: String(qte),
          disabled: Boolean(sscc),
        })
        line.push({
          name: 'total_piece',
          value: String(total_pieses),
          disabled: true,
        })
        line.push({
          name: 'qte_confirmee',
          value: String(qte_confirmee || '0'),
        })
      }
      result.push(line)
    })

  return result
}

export function onResetForceShowWaring(
  name: string,
  index: number,
  forceShowWarning: ForceShowWarning[][]
) {
  forceShowWarning[index] = forceShowWarning[index]?.filter(
    (item) => item.name !== name
  )
}

export function redirectToCmdScreen(
  cmd: CommandeResponse | undefined,
  cmdResponse: CommandeResponse | undefined,
  pageIndex: number,
  pageSize: number
): string {
  if (cmd?.scene === CommandeScene.EditScene) {
    return `/gestion-de-stock/commande/commande-edit/${
      cmdResponse?.id
    }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
  }
  if (cmd?.scene === CommandeScene.TotalScene) {
    return `/gestion-de-stock/commande/commande-envoye-en-mission/${
      cmdResponse?.id
    }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
  }
  if (cmd?.scene === CommandeScene.MiseScene) {
    return `/gestion-de-stock/commande/commande-preparation/${
      cmdResponse?.id
    }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
  }

  if (cmd?.scene === CommandeScene.ChargementScene) {
    return `/gestion-de-stock/commande/commande-envoyer-en-chargement/${
      cmdResponse?.id
    }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
  }
  return ''
}

export const handleNewErrorDetail = (message: string) => {
  message = message.replace(/\s+/g, ' ').trim()
  const listMess = message.split('\n')
  const pattern1 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((was block on reference){1})$/
  const pattern2 = /^(SSCC).*(exist on commande with Num){1}(.*)$/
  const pattern3 = /^(SSCC).*((not exist on stock){1})$/
  const pattern4 = /^(SSCC).*((not enough on stock){1})$/
  const pattern5 = /^(Lot).*((not exist on stock){1})$/
  const pattern6 = /^(Lot).*((not enough on stock){1})$/
  const pattern7 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((was keep by other commande){1})$/
  const pattern8 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((was block on location){1})$/
  const pattern9 = /^(Reference).*((not exist on stock){1})$/
  const pattern10 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have reference blocked){1})$/
  const pattern11 = /^(DLUO was expired)$/
  const pattern12 = /^(DLUO was expired but setting force dluo)$/
  const pattern13 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have location blocked){1})$/
  const pattern14 = /^(No return date)$/
  const pattern15 = /^(DLUO number of months remaining exceeded)$/

  const pattern16 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have DLUO was expired){1})$/
  const pattern17 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have No return date){1})$/
  const pattern18 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have DLUO number of months remaining exceeded){1})$/

  const pattern19 = /^(DLUO non-existent)$/

  const pattern20 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((was keep by other commandes){1})$/
  const pattern21 = /^(Lot).*(with Dluo){1}.*((not enough on stock){1})$/
  const pattern22 = /^(Goods not enough)$/
  const pattern23 =
    /^(Lot).*(with SSCC){1}.*((not match with require in stock){1})$/
  let errorList = []

  for (let i = 0; i < listMess.length; i++) {
    if (pattern1.test(message)) errorList.push('Ref. Blocage sortie')
    else if (pattern2.test(message)) {
      const split = message.split('exist on commande with Num')
      let cmdNo = ''
      if (split.length === 2) cmdNo = split[1]
      errorList.push(`SSCC existe sur Commande Nom ${cmdNo}`)
    } else if (pattern3.test(message)) errorList.push('SSCC non existant')
    else if (pattern4.test(message)) errorList.push('Pas de stock disponible')
    else if (pattern5.test(message)) errorList.push('Lot non existant')
    else if (pattern6.test(message)) errorList.push('Pas de stock disponible')
    else if (pattern7.test(message))
      errorList.push('Lot existe sur autre Commande')
    else if (pattern8.test(message)) errorList.push('Empl. Blocage sortie')
    else if (pattern9.test(message)) errorList.push('Pas de stock disponible')
    else if (pattern10.test(message)) errorList.push('Ref. Blocage sortie')
    else if (pattern11.test(message)) errorList.push('DLUO dépassée')
    else if (pattern12.test(message))
      errorList.push('DLUO dépassée mais Forcer DLUO')
    else if (pattern13.test(message)) errorList.push('Empl. Blocage sortie')
    else if (pattern14.test(message)) errorList.push('Non retour en date')
    else if (pattern15.test(message))
      errorList.push('Nombre de mois restants dépassé')
    else if (pattern16.test(message)) errorList.push('DLUO dépassée')
    else if (pattern17.test(message)) errorList.push('Non retour en date')
    else if (pattern18.test(message))
      errorList.push('Nombre de mois restants dépassé')
    else if (pattern19.test(message)) errorList.push('DLUO non existant')
    else if (pattern20.test(message))
      errorList.push('SSCC existe sur autre Commande')
    else if (pattern21.test(message)) errorList.push('Pas de stock disponible')
    else if (pattern22.test(message)) errorList.push('Pas de stock disponible')
    else if (pattern23.test(message)) errorList.push(t('ssccNonStd'))
    else errorList.push(message)
  }

  errorList = uniq(errorList)

  return errorList
}

export async function uploadPickingList(
  commandeResponse: CommandeResponse,
  statut: DocumentStatus
) {
  await Promise.allSettled([
    uploadPalletePickList({ ...commandeResponse }, statut),
    uploadCartonPickList({ ...commandeResponse }, statut),
  ])
}

export async function uploadNonList(
  commandeResponse: CommandeResponse,
  statut: DocumentStatus
) {
  let numberOfNonList = 0
  const comFile =
    commandeResponse.com_file?.filter(
      (item) => (item.source = sourceType.AUTO)
    ) || []
  const comArticle = commandeResponse.com_article
  for (let i = 0; i < (comFile?.length || 0); i++) {
    const file = comFile[i]
    const fileName = file.name
    if (fileName.includes('Rapport de non mise en préparation'))
      numberOfNonList++
  }
  numberOfNonList += 1
  const nonList =
    comArticle?.filter(
      (item) =>
        item.status_code &&
        [
          StatusCommande.CreatedCommande,
          StatusCommande.ValidCommande,
          StatusCommande.SendOnMissionCommande,
        ].includes(item.status_code) &&
        item.source === sourceType.MANUAL &&
        item.error_details &&
        !item.error_details.includes('SUCCESSED')
    ) || []
  const replaceList =
    comArticle?.filter(
      (item) =>
        item.status_code &&
        [StatusCommande.CreatedCommande, StatusCommande.ValidCommande].includes(
          item.status_code
        ) &&
        item.source === sourceType.MANUAL
    ) || []

  if (nonList.length + getSsccNeedReplace(replaceList).length === 0) return
  let blobNonPdf = await pdf(
    NonMiseList({
      commande: commandeResponse,
      nonList: generateLineFollowErrorDetail(nonList).concat(
        getSsccNeedReplace(replaceList)
      ),
      idWithOrder: `${commandeResponse.nom}-${numberOfNonList}`,
      isForceDluo: commandeResponse.force_dlou,
    })
  ).toBlob()
  let nonFileName = `Rapport de non mise en préparation (n०${commandeResponse.nom}-${numberOfNonList}).pdf`
  let nonFile = new File([blobNonPdf], nonFileName)
  await commandeApi.autoUploadFile({
    file: nonFile,
    commande_id: commandeResponse.id || '',
    description: JSON.stringify({
      fileName: `${commandeResponse.nom}-${numberOfNonList}`,
      displayName: nonFileName,
      statut,
    }),
    created_at: commandeResponse.updated_at,
  })
}

export function generateLineFollowErrorDetail(
  comArticle: ComArticle[]
): ComArticle[] {
  const pattern1 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((was block on reference){1})$/
  const pattern2 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((was keep by other commande){1})$/
  const pattern3 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((was block on location){1})$/

  const pattern4 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have DLUO was expired){1})$/
  const pattern5 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have No return date){1})$/
  const pattern6 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have DLUO number of months remaining exceeded){1})$/

  const pattern7 = /^(SSCC).*(exist on commande with Num){1}(.*)$/
  const pattern8 = /^(SSCC).*((not exist on stock){1})$/
  const pattern9 = /^(SSCC).*((not enough on stock){1})$/
  const pattern10 = /^(Lot).*((not exist on stock){1})$/
  const pattern11 = /^(Lot).*((not enough on stock){1})$/

  const pattern12 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have reference blocked){1})$/
  const pattern13 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((have location blocked){1})$/
  const pattern14 =
    /^(Lot).*(with SSCC){1}.*(and Dluo){1}.*((was keep by other commandes){1})$/
  const pattern15 = /^(Lot).*(with Dluo){1}.*((not enough on stock){1})$/
  const pattern23 =
    /^(Lot).*(with SSCC){1}.*((not match with require in stock){1})$/

  const result = []

  for (let i = 0; i < comArticle.length; i++) {
    const article = comArticle[i]
    let error_details = article.error_details?.split('\n') || []
    error_details = error_details.filter((e) => e.length > 0)
    for (let j = 0; j < error_details.length; j++) {
      const newArticle = cloneDeep(article)
      let currentError = error_details[j]
      currentError = currentError.replace(/\s+/g, ' ').trim()

      if (pattern1.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('and Dluo', '*space*')
          .replace('was block on stock', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, sscc, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }
      if (pattern2.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('and Dluo', '*space*')
          .replace('was keep by other commande', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, sscc, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }
      if (pattern3.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('and Dluo', '*space*')
          .replace('was block on location', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, sscc, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }
      if (pattern4.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('and Dluo', '*space*')
          .replace('have DLUO was expired', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, sscc, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }

      if (pattern5.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('and Dluo', '*space*')
          .replace('have No return date', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, sscc, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }
      if (pattern6.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('and Dluo', '*space*')
          .replace('have DLUO number of months remaining exceeded', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, sscc, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }

      if (pattern7.test(currentError)) {
        const errorReplace = currentError
          .replace('SSCC', '')
          .replace('exist on commande with Num', '*space*')
          .replace(/\s+/g, ' ')
          .trim()

        const values = errorReplace.split('*space*')
        const [sscc, cmdNo] = values
        if (sscc) newArticle.sscc = sscc.trim()
      }

      if (pattern8.test(currentError)) {
        const errorReplace = currentError
          .replace('SSCC', '')
          .replace('not exist on stock', '')
          .replace(/\s+/g, ' ')
          .trim()

        const sscc = errorReplace.trim()
        if (sscc) newArticle.sscc = sscc.trim()
      }

      if (pattern9.test(currentError)) {
        const errorReplace = currentError
          .replace('SSCC', '')
          .replace('not enough on stock', '')
          .replace(/\s+/g, ' ')
          .trim()

        const sscc = errorReplace.trim()
        if (sscc) newArticle.sscc = sscc.trim()
      }

      if (pattern10.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('not exist on stock', '')
          .replace(/\s+/g, ' ')
          .trim()

        const lot = errorReplace.trim()
        if (lot) newArticle.lot = lot.trim()
      }

      if (pattern11.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('not enough on stock', '')
          .replace(/\s+/g, ' ')
          .trim()

        const lot = errorReplace.trim()
        if (lot) newArticle.lot = lot.trim()
      }

      if (pattern12.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('and Dluo', '*space*')
          .replace('have reference blocked', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, sscc, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }

      if (pattern13.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('and Dluo', '*space*')
          .replace('have location blocked', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, sscc, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }

      if (pattern14.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('and Dluo', '*space*')
          .replace('was keep by other commandes', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, sscc, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }

      if (pattern15.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with Dluo', '*space*')
          .replace('not enough on stock', '')
          .replace(/\s+/g, ' ')
          .trim()
        const values = errorReplace.split('*space*')

        const [lot, dluo] = values
        if (lot) newArticle.lot = lot.trim()
        if (dluo) newArticle.dluo = Number(dluo.trim())
      }

      if (pattern23.test(currentError)) {
        const errorReplace = currentError
          .replace('Lot', '')
          .replace('with SSCC', '*space*')
          .replace('not match with require in stock', '')
          .replace(/\s+/g, ' ')
          .trim()

        const values = errorReplace.split('*space*')

        const [lot, sscc] = values
        if (lot) newArticle.lot = lot.trim()
        if (sscc) newArticle.sscc = sscc.trim()
      }

      newArticle.error_details = currentError
      result.push(newArticle)
    }
  }

  return result
}

export function getSsccNeedReplace(com_article: ComArticle[]) {
  const comArticleHasSsccReplace = com_article.filter(
    (item) => item.sscc_motif && item.sscc_motif.length > 0
  )
  const result: ComArticle[] = []
  for (let i = 0; i < comArticleHasSsccReplace.length; i++) {
    const article = comArticleHasSsccReplace[i]
    const ssccMotif = article.sscc_motif.map((item) => JSON.parse(item))

    for (let j = 0; j < ssccMotif.length; j++) {
      const e = ssccMotif[j]
      const newArticle = cloneDeep(article)
      newArticle.error_details = `${e.motif}\n`
      newArticle.sscc = `${e.sscc}`
      newArticle.dluo = e.dluo
      newArticle.lot = `${e.lot || newArticle.lot || ''}`
      result.push(newArticle)
    }
  }

  return result
}

export function mapDescriptionJsonToProperty_comFiles(
  com_files: FileResponse[]
) {
  let newComFiles = cloneDeep(com_files)

  newComFiles = newComFiles.map((file) => {
    const description = JSON.parse(file.description || '{}')
    if (file.name.includes('Feuille de récap') && !file.description) {
      file.statut = DocumentStatus.Effectuee
    } else {
      file.statut = description.statut
      file.cancel_at = description.cancel_at
    }

    return file
  })

  return newComFiles
}

export function setStateForFileWhenMeter(
  files: FileResponse[]
): FileResponse[] {
  let newFiles = cloneDeep(files)
  let manualFiles =
    newFiles.filter((item) => item.source === sourceType.MANUAL) || []
  let autoFiles =
    newFiles.filter((item) => item.source === sourceType.AUTO) || []
  let pickingFiles =
    autoFiles.filter((item) =>
      [DocumentType.Pallete, DocumentType.Carton].includes(
        JSON.parse(item.description || '{}')?.type
      )
    ) || []
  let rapportFiles =
    autoFiles.filter((item) =>
      item.name.includes('Rapport de non mise en préparation')
    ) || []
  let recapFile = autoFiles.filter((item) =>
    item.name.includes('Feuille de récap')
  )

  manualFiles = manualFiles.map((file) => {
    const description: FileDescription = JSON.parse(
      file.description || '{}'
    ) as FileDescription
    description.statut = DocumentStatus.DASH
    file.description = JSON.stringify(description)
    return file
  })

  pickingFiles = pickingFiles.map((file) => {
    const description: FileDescription = JSON.parse(file.description || '{}')
    if (
      description.statut !== DocumentStatus.Annule &&
      description.statut !== DocumentStatus.Envoye
    ) {
      description.statut = DocumentStatus.Obsolete
    }
    file.description = JSON.stringify(description)
    return file
  })

  rapportFiles = rapportFiles.map((file) => {
    const description = JSON.parse(file.description || '{}')
    description.statut = DocumentStatus.DASH
    file.description = JSON.stringify(description)
    return file
  })

  recapFile = recapFile.map((file) => {
    const description = JSON.parse(file.description || '{}')
    description.statut = DocumentStatus.Effectuee
    file.description = JSON.stringify(description)
    return file
  })

  newFiles = manualFiles
    .concat(pickingFiles)
    .concat(rapportFiles)
    .concat(recapFile)

  return newFiles
}

export function setStateForFileWhenAnnulerMise(
  files: FileResponse[]
): FileResponse[] {
  let newFiles = cloneDeep(files)

  return newFiles.map((item) => {
    if (
      item.source === sourceType.AUTO &&
      [DocumentType.Pallete, DocumentType.Carton].includes(
        JSON.parse(item.description || '{}')?.type
      ) &&
      JSON.parse(item.description || '{}').statut ===
        DocumentStatus.Ajour.toString()
    ) {
      const description = JSON.parse(item.description || '{}')
      description.statut = DocumentStatus.Annule
      description.cancel_at = new Date().getTime()
      item.description = JSON.stringify(description)
    }

    return item
  })
}

export function setStateForFileWhenEnvoyePR(
  files: FileResponse[],
  chosenFiles: Array<{ id: string; name: string }>
): FileResponse[] {
  let newFiles = cloneDeep(files).sort(
    (a, b) => (b.created_at || 0) - (a.created_at || 0)
  )

  return newFiles.map((file) => {
    if (chosenFiles.find((i) => i.id === file.id)) {
      const description = JSON.parse(file.description || '{}')

      description.statut = DocumentStatus.Envoye.toString()
      description.sendMissionPrAt = new Date().getTime()
      file.description = JSON.stringify(description)
    }
    return file
  })
}

export const initDataOfDetailTable = (
  data: DataTableParam[][],
  noNeedValidQteConfirm?: boolean
) => {
  let newData = cloneDeep(data)
  newData = newData.map((line) => {
    const id = line.find((item) => item.name === 'id')?.value
    const parentId = line.find((item) => item.name === 'parent_id')

    const ssccIndex = line.findIndex((item) => item.name === 'sscc')
    const conditionementLevel = line.findIndex(
      (item) => item.name === 'conditionement_level'
    )

    if (ssccIndex === -1) return line
    if (
      parentId &&
      parentId.value &&
      id &&
      // only show refresh action with detail not highest level
      line[conditionementLevel]?.value == define.MAX_CDN_LEVEL.toString()
    ) {
      line[ssccIndex].refreshAction = true
    } else if (id) {
      line[ssccIndex].refreshAction = false

      const qte = line.find((item) => item.name === 'qte')
      const qteConfirmeeIndex = line.findIndex(
        (item) => item.name === 'qte_confirmee'
      )

      const qteValue = Number(qte?.value || '1')

      // calculate sum of qte's confirmee with same parent's id
      const totalQteConfirmSameParent = newData
        .filter(
          (line) =>
            line.find((item) => item.name === 'parent_id')?.value === id &&
            line.find((item) => item.name === 'id')?.value &&
            line.find((item) => item.name === 'is_color')?.value
        )
        .reduce((totalQteConfirm, line) => {
          const qteConfirmeeVal = line.find(
            (item) => item.name === 'qte_confirmee'
          )?.value
          return (
            totalQteConfirm + (qteConfirmeeVal ? parseInt(qteConfirmeeVal) : 0)
          )
        }, 0)
      // set validation rule for qte confirm number when edit
      if (line[qteConfirmeeIndex] && !noNeedValidQteConfirm)
        line[qteConfirmeeIndex].validates = [
          {
            order: 1,
            validateFunction: (value) => {
              return (
                isGreatThan(Number(value), totalQteConfirmSameParent - 1) &&
                isLessThan(Number(value), qteValue + 1)
              )
            },
            message: t('errorQteConfirmNumber'),
            realtime: true,
          },
        ]
    }
    line[ssccIndex].disabled = true

    return line
  })

  return newData
}

export function getDetailLinesNeedAndNotNeedToRemove(
  difference: number,
  clientRule: ClientRule,
  data: DataTableParam[][],
  parentLine: DataTableParam[],
  initData: DataTableParam[][]
) {
  const parentId = parentLine.find((item) => item.name === 'id')?.value
  const isHighestLevel =
    parentLine.find((item) => item.name === 'conditionement_level')?.value ===
    define.MAX_CDN_LEVEL.toString()
  if (!parentId) return

  let detailLines = cloneDeep(isHighestLevel ? data : initData).filter(
    (line) =>
      line.find((item) => item.name === 'parent_id')?.value === parentId &&
      line.find((item) => item.name === 'id')?.value
  )
  if (difference >= 0) {
    const dataClone = cloneDeep(data).filter(
      (line) =>
        line.find((item) => item.name === 'id')?.value ||
        line.find((item) => item.name === 'parent_id')?.value !== parentId
    )
    if (isNotHighestItemLevel(detailLines)) {
      detailLines = detailLinesAfterRemove(detailLines, difference, clientRule)
    } else {
      detailLines = detailLinesHighestLevelAfterRemove(
        detailLines,
        difference,
        clientRule
      )
    }
    if (!isHighestLevel) {
      resetDetailLineLikeInitData(dataClone, initData, parentId)
    }
    const dataLines = cloneDeep(data)
    const waitingLineIndex = dataLines.findIndex(
      (line) =>
        !line.find((item) => item.name === 'id')?.value &&
        line.find((item) => item.name === 'sscc')?.value === t('waitingLine') &&
        line.find((item) => item.name === 'parent_id')?.value === parentId
    )
    if (waitingLineIndex !== -1) {
      dataLines.splice(waitingLineIndex, 1)
    }
    return isHighestLevel
      ? mapRemoveLineToDataHighest(cloneDeep(dataLines), detailLines, parentId)
      : mapRemoveLineToData(cloneDeep(dataLines), detailLines, parentId)
  } else {
    return addEmptyLineToData(
      forceAllDetailLineBackToNotMustRemove(cloneDeep(data), parentId),
      difference,
      parentLine,
      forceAllDetailLineBackToNotMustRemove(cloneDeep(detailLines), parentId)
    )
  }
}

function resetDetailLineLikeInitData(
  data: DataTableParam[][],
  initData: DataTableParam[][],
  parentId: string
) {
  const parentLineIndex = data.findIndex(
    (line) => line.find((item) => item.name === 'id')?.value === parentId
  )
  const detailLineCount = data.filter(
    (line) => line.find((item) => item.name === 'parent_id')?.value === parentId
  ).length
  data.splice(parentLineIndex + 1, detailLineCount)
  let addedDetailCount = 0
  initData.forEach((line) => {
    if (line?.find((item) => item.name === 'parent_id')?.value === parentId) {
      data.splice(parentLineIndex + addedDetailCount + 1, 0, line)
      addedDetailCount++
    }
  })
}

export function lockLineAfterConfirm(
  data: DataTableParam[][],
  actions: ActionParam[],
  lineParentIndexConfirmed: number,
  lineParentIdConfirmed?: string
) {
  // filter list detail line of confirmed parent line
  const detailWithCurrentParentConfirmed = data.filter(
    (line) =>
      line.find((item) => item.name === 'parent_id')?.value ===
      lineParentIdConfirmed
  )

  // calculate list of actions inside confirmed parent line should display
  const listActionsAdded = detailWithCurrentParentConfirmed.map((line, i) => {
    const parent_id = line.find((item) => item.name === 'parent_id')?.value
    const isSent = line.find((item) => item.name === 'is_color')?.value

    const conditionementLevel = line.find(
      (item) => item.name === 'conditionement_level'
    )?.value
    return {
      isAllowDelete: false,
      isAllowEdit: true,
      isFillAllRequired: true,
      isForceValid: true,
      isValid: true,
      parentLineId: parent_id,
      isShowAction:
        !Boolean(isSent) &&
        conditionementLevel === define.MAX_CDN_LEVEL.toString(),
    }
  })

  // remove all old actions of parent line then add new action calculated
  actions.splice(
    lineParentIndexConfirmed + 1,
    actions.filter((item) => item.parentLineId === lineParentIdConfirmed)
      .length,
    ...listActionsAdded
  )
  return cloneDeep(actions)
}

function detailLinesHighestLevelAfterRemove(
  detailLines: DataTableParam[][],
  difference: number,
  clientRule: ClientRule
) {
  detailLines = detailLines.filter(
    (line) => !line.find((item) => item.name === 'is_color')?.value
  )

  const length = detailLines.length
  if (difference > 0) {
    if (clientRule === ClientRule.FIFO) {
      detailLines = detailLines.sort((line1, line2) => {
        const createAt1 = line1.find(
          (item) => item.name === 'stock_created_at'
        )?.value
        const createAt2 = line2.find(
          (item) => item.name === 'stock_created_at'
        )?.value
        return Number(createAt1) - Number(createAt2)
      })

      for (let i = 0; i < difference; i++) {
        const detailRemoved = removeDetailBySscc(detailLines[length - 1 - i])
        if (detailRemoved) {
          detailLines[length - 1 - i] = detailRemoved
        }
      }

      for (let i = difference; i < length; i++) {
        const detail = keepDetailBySscc(detailLines[length - 1 - i])
        if (detail) {
          detailLines[length - 1 - i] = detail
        }
      }
    } else {
      detailLines = detailLines.sort((line1, line2) => {
        const dluo1 = line1.find((item) => item.name === 'dluo')?.value
        const dluo2 = line2.find((item) => item.name === 'dluo')?.value
        const dluoFake100years1 = line1.find(
          (item) => item.name === 'dluoFake100years'
        )?.value
        const dluoFake100years2 = line2.find(
          (item) => item.name === 'dluoFake100years'
        )?.value

        const represent1 = dluo1
          ? ddMMyyyyTo_date(dluo1).getTime()
          : Number(dluoFake100years1)
        const represent2 = dluo2
          ? ddMMyyyyTo_date(dluo2).getTime()
          : Number(dluoFake100years2)

        return represent1 - represent2
      })

      for (let i = 0; i < difference; i++) {
        const detailRemoved = removeDetailBySscc(detailLines[length - 1 - i])
        if (detailRemoved) {
          detailLines[length - 1 - i] = detailRemoved
        }
      }

      for (let i = difference; i < length; i++) {
        const detail = keepDetailBySscc(detailLines[length - 1 - i])
        if (detail) {
          detailLines[length - 1 - i] = detail
        }
      }
    }

    return detailLines
  } else if (difference === 0) {
    detailLines = removeAllDetailBySscc(detailLines)
    return detailLines
  }

  return detailLines
}

function updateQteConfirmDetail(
  data: DataTableParam[][],
  qteConfirmNeedMinus: number,
  addedList: Array<any>
) {
  for (let i = data.length - 1; i >= 0; i--) {
    const detail = data[i]
    let qteConfirm = detail.find((item) => item.name === 'qte_confirmee')?.value
    if (qteConfirm) {
      if (parseInt(qteConfirm) <= qteConfirmNeedMinus) {
        qteConfirmNeedMinus = qteConfirmNeedMinus - parseInt(qteConfirm)
        const detailRemoved = removeDetailBySscc(detail)
        if (detailRemoved) {
          data[i] = detailRemoved
        }
      } else {
        if (qteConfirmNeedMinus > 0) {
          const keptDetail = keepDetailBySscc(detail)
          if (keptDetail) {
            let keptQteConfirmeeIndex = keptDetail?.findIndex(
              (item) => item.name === 'qte_confirmee'
            )
            if (keptQteConfirmeeIndex) {
              keptDetail[keptQteConfirmeeIndex].value = `${
                parseInt(keptDetail[keptQteConfirmeeIndex]?.value || '0') -
                qteConfirmNeedMinus
              }`
            }
            data[i] = keptDetail
          }
          const detailRemoved = removeDetailBySscc(detail)
          if (detailRemoved) {
            let detailRemovedQteConfirmIndex = detailRemoved.findIndex(
              (item) => item.name === 'qte_confirmee'
            )
            detailRemoved[detailRemovedQteConfirmIndex].value =
              qteConfirmNeedMinus.toString()
            addedList.push(detailRemoved)
          }
          qteConfirmNeedMinus = 0
        } else {
          const keptDetail = keepDetailBySscc(detail)
          if (keptDetail) {
            data[i] = keptDetail
          }
        }
      }
    }
  }
}

function detailLinesAfterRemove(
  detailLines: DataTableParam[][],
  difference: number,
  clientRule: ClientRule
) {
  let data = detailLines.filter(
    (line) =>
      !line.find((item) => item.name === 'is_color')?.value &&
      line.find((item) => item.name === 'sscc')?.value !== t('waitingLine')
  )
  let addedList: any[] = []
  if (difference > 0) {
    let qteConfirmNeedMinus = Number(difference)
    if (clientRule === ClientRule.FIFO) {
      data = data.sort((line1, line2) => {
        const createAt1 = line1.find(
          (item) => item.name === 'stock_created_at'
        )?.value
        const createAt2 = line2.find(
          (item) => item.name === 'stock_created_at'
        )?.value
        return Number(createAt1) - Number(createAt2)
      })
      updateQteConfirmDetail(data, qteConfirmNeedMinus, addedList)
    } else {
      data = data.sort((line1, line2) => {
        const dluo1 = line1.find((item) => item.name === 'dluo')?.value
        const dluo2 = line2.find((item) => item.name === 'dluo')?.value
        const dluoFake100years1 = line1.find(
          (item) => item.name === 'dluoFake100years'
        )?.value
        const dluoFake100years2 = line2.find(
          (item) => item.name === 'dluoFake100years'
        )?.value

        const represent1 = dluo1
          ? ddMMyyyyTo_date(dluo1).getTime()
          : Number(dluoFake100years1)
        const represent2 = dluo2
          ? ddMMyyyyTo_date(dluo2).getTime()
          : Number(dluoFake100years2)

        return represent1 - represent2
      })
      updateQteConfirmDetail(data, qteConfirmNeedMinus, addedList)
    }
  } else if (difference === 0) {
    data = removeAllDetailBySscc(data)
    return data
  }
  return [...data, ...addedList]
}

function removeDetailBySscc(data: DataTableParam[]) {
  const detail = cloneDeep(data)
  if (!detail) return null
  const ssccIndex = detail.findIndex((item) => item.name === 'sscc')
  const ssccBlockIndex = detail.findIndex((item) => item.name === 'sscc_block')
  const ssccMotifIndex = detail.findIndex((item) => item.name === 'sscc_motif')
  if (ssccIndex === -1 || ssccBlockIndex === -1 || ssccMotifIndex === -1)
    return null
  const sscc = detail[ssccIndex].value
  detail[ssccIndex].mustRemove = true
  detail[ssccIndex].mustRefresh = false

  // remove sscc was added to sscc_block and sscc_motif list. Because sscc must be removed don't be added to sscc_block and sscc_motif list
  const ssccBlock: string[] = JSON.parse(detail[ssccBlockIndex].value || '[]')
  const ssccMotif: string[] = JSON.parse(detail[ssccMotifIndex].value || '[]')

  detail[ssccBlockIndex].value = JSON.stringify(
    ssccBlock.filter((item) => item !== sscc)
  )
  detail[ssccMotifIndex].value = JSON.stringify(
    ssccMotif.filter((item) => JSON.parse(item).sscc !== sscc)
  )
  //---------------------------------------------------------------------------------------------------------------------------------------

  const motifIndex = detail.findIndex((item) => item.name === 'motif')
  if (motifIndex !== -1) detail[motifIndex].value = ''
  const isBlockIndex = detail.findIndex((item) => item.name === 'isBlock')
  detail[isBlockIndex].value = '1'
  return detail
}

function keepDetailBySscc(data: DataTableParam[]) {
  const detail = cloneDeep(data)
  const ssccIndex = detail.findIndex((item) => item.name === 'sscc')
  if (ssccIndex === -1) return null
  detail[ssccIndex].mustRemove = false
  const isBlockIndex = detail.findIndex((item) => item.name === 'isBlock')
  detail[isBlockIndex].value = '0'
  return detail
}

function removeAllDetailBySscc(detailLines: DataTableParam[][]) {
  return detailLines
    .filter((line) => line.find((item) => item.name === 'id')?.value)
    .map((line) => {
      const ssccIndex = line.findIndex((item) => item.name === 'sscc')
      const isBlockIndex = line.findIndex((item) => item.name === 'isBlock')
      if (ssccIndex !== -1) line[ssccIndex].mustRemove = false
      if (isBlockIndex !== -1) line[isBlockIndex].value = '0'
      return line
    })
}

function addEmptyLineToData(
  data: DataTableParam[][],
  difference: number,
  parentLine: DataTableParam[],
  detailLines: DataTableParam[][]
) {
  const dataClone = cloneDeep(data)

  const parentId = parentLine.find((item) => item.name === 'id')?.value
  const total_pieses = parentLine.find(
    (item) => item.name === 'total_piece'
  )?.value
  const qte = parentLine.find((item) => item.name === 'qte')?.value
  const cdn = parentLine.find((item) => item.name === 'cdn')
  const cdnValue = cdn?.value
  const cdnOptions = cdn?.options

  if (isNotHighestItemLevel(detailLines)) {
    dataClone.splice(
      dataClone.findIndex(
        (l) => l.find((item) => item.name === 'id')?.value === parentId
      ) + 1,
      dataClone.filter(
        (l) => l.find((item) => item.name === 'parent_id')?.value === parentId
      )?.length || 0,
      ...detailLines
    )
  }
  const waitingLine: DataTableParam[] = [
    {
      name: 'id',
      value: '',
      isHide: true,
      disabled: true,
    },
    {
      name: 'reference_id',
      value: '',
      isHide: true,
      disabled: true,
    },
    {
      name: 'reference',
      value: '-',
      disabled: true,
      forceInvisible: true,
    },
    {
      name: 'libelle',
      value: '-',
      disabled: true,
      forceInvisible: true,
    },
    {
      name: 'lot',
      value: '-',
      disabled: true,
      forceInvisible: true,
    },
    {
      name: 'dluo',
      value: '-',
      disabled: true,
      forceInvisible: true,
    },
    {
      name: 'statut',
      value: '-',
      disabled: true,
      forceInvisible: true,
    },
    {
      name: 'cdn',
      value: cdnValue,
      // forceInvisible: true,
      options: cdnOptions,
      disabled: true,
    },
    {
      name: 'sscc',
      value: t('waitingLine'),
      refreshAction: false,
      disabled: true,
    },
    {
      name: 'total_piece',
      value: `${(Number(total_pieses) / Number(qte)) * Math.abs(difference)}`,
      disabled: true,
    },
    {
      name: 'qte',
      value: '-',
      disabled: true,
      forceInvisible: true,
    },
    {
      name: 'sous_qte',
      value: '-',
      disabled: true,
    },
    {
      name: 'volume_sousqte',
      value: '-',
      disabled: true,
    },
    {
      name: 'volume_qte',
      value: '-',
      disabled: true,
    },
    {
      name: 'poids_qte',
      value: '-',
      disabled: true,
    },
    {
      name: 'poids_sousqte',
      value: '-',
      disabled: true,
    },
    {
      name: 'parent_id',
      value: parentId,
      disabled: true,
    },
    {
      name: 'poids_sousqte',
      value: '-',
      disabled: true,
    },
    {
      name: 'description',
      value: '-',
      disabled: true,
    },
    {
      name: 'source',
      value: 'AUTO',
      disabled: true,
    },
    {
      name: 'manquants',
      value: '-',
      disabled: true,
    },
    {
      name: 'mission_id',
      disabled: true,
    },
    {
      name: 'is_color',
      value: 'false',
      disabled: true,
    },
    {
      name: 'created_at',
      value: '1698721845',
      isHide: true,
      disabled: true,
    },
    {
      name: 'dluoFake100years',
      value: '3155298721845',
      isHide: true,
      disabled: true,
    },
    {
      name: 'motif',
      value: '-',
      disabled: false,
      forceInvisible: true,
    },
    {
      name: 'qte_confirmee',
      value: `${Math.abs(difference)}`,
      disabled: true,
    },
  ]

  const lastSameParentIndex = findLastIndex(
    dataClone,
    (line) => line.find((item) => item.name === 'parent_id')?.value === parentId
  )

  if (lastSameParentIndex !== -1) {
    if (
      !dataClone[lastSameParentIndex].find((item) => item.name === 'id')?.value
    ) {
      dataClone[lastSameParentIndex] = waitingLine
    } else dataClone.splice(lastSameParentIndex + 1, 0, waitingLine)
  }
  if (lastSameParentIndex === -1) {
    const parentIndex = dataClone.findIndex(
      (line) => line.find((item) => item.name === 'id')?.value === parentId
    )
    dataClone.splice(parentIndex + 1, 0, waitingLine)
  }

  return dataClone
}

function mapRemoveLineToDataHighest(
  data: DataTableParam[][],
  detailLines: DataTableParam[][],
  parentEditingId: string
) {
  const detailLinesWasMustRemove = (
    detailLines.filter(
      (line) => line.find((item) => item.name === 'sscc')?.mustRemove
    ) || []
  ).map((line) => line.find((item) => item.name === 'id')?.value)
  const result = cloneDeep(data).map((line, index) => {
    const parentId = line.find((item) => item.name === 'parent_id')?.value
    if (parentId !== parentEditingId) {
      return line
    }
    const id = line.find((item) => item.name === 'id')?.value
    const ssccIndex = line.findIndex((item) => item.name === 'sscc')
    const isBlockIndex = line.findIndex((item) => item.name === 'isBlock')
    if (ssccIndex !== -1) {
      line[ssccIndex].mustRemove = detailLinesWasMustRemove.includes(id)
      const qteConfirmIndex = line.findIndex(
        (item) => item.name === 'qte_confirmee'
      )
      if (qteConfirmIndex !== -1) {
        line[isBlockIndex].value = detailLinesWasMustRemove.includes(id)
          ? '1'
          : '0'
      }
    }
    return line
  })
  return result
}

function mapRemoveLineToData(
  data: DataTableParam[][],
  detailLines: DataTableParam[][],
  parentEditingId: string
) {
  const detailLinesWasMustRemove = (
    detailLines.filter(
      (line) => line.find((item) => item.name === 'sscc')?.mustRemove
    ) || []
  ).map((line) => line.find((item) => item.name === 'id')?.value)
  let addRemovedLine: any[] = []
  const detailLinesEdited = cloneDeep(data).filter(
    (l) =>
      l.find((item) => item.name === 'parent_id')?.value !== parentEditingId ||
      !l.find((item) => item.name === 'sscc')?.mustRemove
  )
  const result = detailLinesEdited.map((line, index) => {
    const parentId = line.find((item) => item.name === 'parent_id')?.value
    if (parentId !== parentEditingId) {
      return line
    }
    const cloneLine = cloneDeep(line)
    const id = line.find((item) => item.name === 'id')?.value
    const ssccIndex = line.findIndex((item) => item.name === 'sscc')
    const isBlockIndex = line.findIndex((item) => item.name === 'isBlock')
    if (ssccIndex !== -1) {
      line[ssccIndex].mustRemove = detailLinesWasMustRemove.includes(id)
      const qteConfirmIndex = line.findIndex(
        (item) => item.name === 'qte_confirmee'
      )
      const detailLinesWithSameId = detailLines.find(
        (item) =>
          item.find((re) => re.name === 'id')?.value === id &&
          item.find((re) => re.name === 'parent_id')?.value === parentId &&
          item.find((re) => re.name === 'conditionement_level')?.value !=
            define.MAX_CDN_LEVEL.toString()
      )
      if (detailLinesWithSameId) {
        line[qteConfirmIndex].value =
          detailLinesWithSameId.find((item) => item.name === 'qte_confirmee')
            ?.value || line[qteConfirmIndex].value
      }
      if (qteConfirmIndex !== -1 && detailLinesWasMustRemove.includes(id)) {
        line[isBlockIndex].value = '1'
        const detailLinesWithSameIdRemoved = detailLines.findIndex(
          (item) =>
            item.find((re) => re.name === 'id')?.value === id &&
            item.find((re) => re.name === 'parent_id')?.value === parentId &&
            item.find((re) => re.name === 'sscc')?.mustRemove === true &&
            item.find((re) => re.name === 'conditionement_level')?.value !=
              define.MAX_CDN_LEVEL.toString()
        )
        if (detailLinesWithSameIdRemoved !== -1) {
          line[qteConfirmIndex].value = detailLines[
            detailLinesWithSameIdRemoved
          ]?.find((item) => item.name === 'qte_confirmee')?.value
        }
        const detailLinesWithSameIdNoRemove = detailLines.findIndex(
          (item) =>
            item.find((re) => re.name === 'id')?.value === id &&
            item.find((re) => re.name === 'parent_id')?.value === parentId &&
            item.find((re) => re.name === 'sscc')?.mustRemove === false &&
            item.find((re) => re.name === 'conditionement_level')?.value !=
              define.MAX_CDN_LEVEL.toString()
        )
        if (
          detailLinesWithSameIdNoRemove !== -1 &&
          detailLinesWithSameIdRemoved !== -1
        ) {
          cloneLine[qteConfirmIndex].value = detailLines[
            detailLinesWithSameIdNoRemove
          ]?.find((item) => item.name === 'qte_confirmee')?.value
          cloneLine[ssccIndex].mustRemove = false
          addRemovedLine.push({
            position: index,
            data: cloneLine,
          })
        }
      }
    }
    return line
  })
  addRemovedLine.forEach((item) => {
    result.splice(item.position, 0, item.data)
  })
  if (addRemovedLine.length === 0) {
    let lessLine: DataTableParam[][] = []
    detailLines.forEach((line) => {
      const id = line.find((item) => item.name === 'id')?.value
      if (
        !result.find((l) => l.find((item) => item.name === 'id')?.value === id)
      ) {
        let ssccIndex = line.findIndex((item) => item.name === 'sscc')
        line[ssccIndex].disabled = false
        line[ssccIndex].refreshAction = false
        lessLine.push(line)
      }
    })
    if (lessLine.length > 0) {
      const parentIndex = result.findIndex(
        (line) =>
          line.find((item) => item.name === 'id')?.value === parentEditingId
      )
      result.splice(parentIndex + 1, 0, ...lessLine)
    }
  }
  return result
}

function forceAllDetailLineBackToNotMustRemove(
  detailLines: DataTableParam[][],
  parentIdEdited: string
) {
  return detailLines.map((line) => {
    const parentId = line.find((item) => item.name === 'parent_id')?.value
    if (parentId === parentIdEdited) {
      const ssccIndex = line.findIndex((item) => item.name === 'sscc')
      const isBlockIndex = line.findIndex((item) => item.name === 'isBlock')
      if (ssccIndex !== -1) line[ssccIndex].mustRemove = false
      if (isBlockIndex !== -1) line[isBlockIndex].value = '0'
    }

    return line
  })
}

export function mapSsccMotifToParentLine(data: DataTableParam[][]) {
  let dataClone = cloneDeep(data)

  for (let i = 0; i < dataClone.length; i++) {
    const line = dataClone[i]
    const parentId = line.find((item) => item.name === 'parent_id')?.value
    if (!parentId) continue

    const sscc = line.find((item) => item.name === 'sscc')
    const motif = line.find((item) => item.name === 'motif')
    const dluo = line.find((item) => item.name === 'dluo')
    const lot = line.find((item) => item.name === 'lot')
    const indexOfParent = dataClone.findIndex(
      (line) => line.find((item) => item.name === 'id')?.value === parentId
    )
    if (indexOfParent === -1) continue

    const ssccBlockIndex = dataClone[indexOfParent].findIndex(
      (item) => item.name === 'sscc_block'
    )
    const ssccMotifOfParentIndex = dataClone[indexOfParent].findIndex(
      (item) => item.name === 'sscc_motif'
    )

    if (ssccMotifOfParentIndex === -1 || ssccBlockIndex === -1 || !sscc?.value)
      continue

    let listSsccBlock: string[] =
      JSON.parse(dataClone[indexOfParent][ssccBlockIndex].value || '[]') || []
    let listSsccMotif: string[] =
      JSON.parse(
        dataClone[indexOfParent][ssccMotifOfParentIndex].value || '[]'
      ) || []

    listSsccMotif = listSsccMotif.filter(
      (item) => JSON.parse(item).sscc !== sscc.value
    )
    listSsccBlock = listSsccBlock.filter((item) => item !== sscc.value)

    if (sscc?.mustRefresh) {
      listSsccMotif.push(
        JSON.stringify({
          sscc: sscc.value,
          motif: motif?.value || '',
          dluo: dluo?.value || '',
          lot: lot?.value || '',
          needCountForManquants: true,
        })
      )
      listSsccBlock.push(sscc.value)
    }

    dataClone[indexOfParent][ssccMotifOfParentIndex].value =
      JSON.stringify(listSsccMotif)
    dataClone[indexOfParent][ssccBlockIndex].value =
      JSON.stringify(listSsccBlock)
  }

  return dataClone
}

export function mapRuptureCheckLotToMainLine(data: DataTableParam[][]) {
  const mainLineIds = data
    .filter((line) => !line.find((item) => item.name === 'parent_id')?.value)
    .map((line) => line.find((item) => item.name === 'id')?.value)

  for (let i = 0; i < mainLineIds.length; i++) {
    const parentId = mainLineIds[i]
    const mainLineIndex = data.findIndex(
      (line) => line.find((item) => item.name === 'id')?.value === parentId
    )
    if (mainLineIndex === -1) continue

    const rCheckLotIndex = data[mainLineIndex].findIndex(
      (item) => item.name === 'ruptures_check_lot'
    )
    if (rCheckLotIndex === -1) continue

    const qteConfirm = data[mainLineIndex].find(
      (item) => item.name === 'qte_confirmee'
    )?.value
    const numberOfDetailLine =
      data.filter(
        (line) =>
          line.find((item) => item.name === 'parent_id')?.value === parentId &&
          line.find((item) => item.name === 'id')?.value &&
          !line.find((item) => item.name === 'sscc')?.mustRemove
      ).length || 0
    let ssccMotif = data[mainLineIndex].find(
      (item) => item.name === 'sscc_motif'
    )?.value
    const ssccMotifParse = JSON.parse(ssccMotif || '[]') as string[]
    const numberOfSSccMotifNeedCount = ssccMotifParse.filter(
      (item) => JSON.parse(item || '{}').needCountForManquants
    ).length

    let rCheckLot =
      Number(qteConfirm) - numberOfDetailLine + numberOfSSccMotifNeedCount
    if (rCheckLot < 0) rCheckLot = 0
    data[mainLineIndex][rCheckLotIndex].value = `${rCheckLot}`
  }

  return data
}

export function addEmptyLineWhenInit(
  data: DataTableParam[][],
  scene?: CommandeScene
) {
  // not add waiting line if in these screen
  if (
    [
      CommandeScene.EditScene,
      CommandeScene.ChargementScene,
      CommandeScene.TotalScene,
      // @ts-ignore
    ].includes(scene)
  )
    return data
  const dataClone = cloneDeep(data)

  const parentLines = dataClone.filter(
    (line) => !line.find((item) => item.name === 'parent_id')?.value
  )
  for (let i = 0; i < parentLines.length; i++) {
    const parentLine = parentLines[i]
    const parentId = parentLine.find((item) => item.name === 'id')?.value
    const qteIndex = parentLine.findIndex((item) => item.name === 'qte')

    const qteConfirmeeIndex = parentLine.findIndex(
      (item) => item.name === 'qte_confirmee'
    )
    const totalPiesesIndex = parentLine.findIndex(
      (item) => item.name === 'total_piece'
    )
    const ssccBlockIndex = parentLine.findIndex(
      (item) => item.name === 'sscc_block'
    )
    if (
      [qteIndex, qteConfirmeeIndex, totalPiesesIndex, ssccBlockIndex].includes(
        -1
      )
    )
      continue

    const qte = Number(parentLine[qteIndex].value || 0)
    // const manquants =
    //   manquantsIndex === -1 ? 0 : Number(parentLine[manquantsIndex].value || 0)
    const qteConfirm = Number(parentLine[qteConfirmeeIndex].value || 0)
    const total_pieses = Number(
      parentLine.find((item) => item.name === 'total_piece')?.value || 0
    )
    const totalDetailLineQteConfirm = getTotalQteConfirmOfLine(
      dataClone,
      parentId
    )
    // const difference = qteConfirm - (qte - manquants) + numberOfSsccBlock
    // const difference = qteConfirm - (qte - manquants);
    const difference = qteConfirm - totalDetailLineQteConfirm
    const cdn = parentLine.find((item) => item.name === 'cdn')
    const cdnValue = cdn?.value
    const cdnOptions = cdn?.options

    const waitingLine: DataTableParam[] = [
      {
        name: 'id',
        value: '',
        isHide: true,
        disabled: true,
      },
      {
        name: 'reference_id',
        value: '',
        isHide: true,
        disabled: true,
      },
      {
        name: 'reference',
        value: '-',
        disabled: true,
        forceInvisible: true,
      },
      {
        name: 'libelle',
        value: '-',
        disabled: true,
        forceInvisible: true,
      },
      {
        name: 'lot',
        value: '-',
        disabled: true,
        forceInvisible: true,
      },
      {
        name: 'dluo',
        value: '-',
        disabled: true,
        forceInvisible: true,
      },
      {
        name: 'statut',
        value: '-',
        disabled: true,
        forceInvisible: true,
      },
      {
        name: 'cdn',
        value: cdnValue,
        // forceInvisible: true,
        options: cdnOptions,
        disabled: true,
      },
      {
        name: 'sscc',
        value: t('waitingLine'),
        refreshAction: false,
        disabled: true,
      },
      {
        name: 'total_piece',
        value: `${(Number(total_pieses) / Number(qte)) * Math.abs(difference)}`,
        disabled: true,
      },
      {
        name: 'qte',
        value: '-',
        disabled: true,
        forceInvisible: true,
      },
      {
        name: 'sous_qte',
        value: '-',
        disabled: true,
      },
      {
        name: 'volume_sousqte',
        value: '-',
        disabled: true,
      },
      {
        name: 'volume_qte',
        value: '-',
        disabled: true,
      },
      {
        name: 'poids_qte',
        value: '-',
        disabled: true,
      },
      {
        name: 'poids_sousqte',
        value: '-',
        disabled: true,
      },
      {
        name: 'parent_id',
        value: parentId,
        disabled: true,
      },
      {
        name: 'poids_sousqte',
        value: '-',
        disabled: true,
      },
      {
        name: 'description',
        value: '-',
        disabled: true,
      },
      {
        name: 'source',
        value: 'AUTO',
        disabled: true,
      },
      {
        name: 'manquants',
        value: '-',
        disabled: true,
      },
      {
        name: 'mission_id',
        disabled: true,
      },
      {
        name: 'is_color',
        value: 'false',
        disabled: true,
      },
      {
        name: 'created_at',
        value: '1698721845',
        isHide: true,
        disabled: true,
      },
      {
        name: 'dluoFake100years',
        value: '3155298721845',
        isHide: true,
        disabled: true,
      },
      {
        name: 'motif',
        value: '-',
        disabled: false,
        forceInvisible: true,
      },
      {
        name: 'qte_confirmee',
        value: `${Math.abs(difference)}`,
        disabled: true,
      },
    ]
    if (difference === 0) continue
    if (difference < 0) continue
    console.log(difference)
    const lastSameParentIndex = findLastIndex(
      dataClone,
      (line) =>
        line.find((item) => item.name === 'parent_id')?.value === parentId
    )

    if (lastSameParentIndex !== -1) {
      dataClone.splice(lastSameParentIndex + 1, 0, waitingLine)
    }
    if (lastSameParentIndex === -1) {
      const parentIndex = dataClone.findIndex(
        (line) => line.find((item) => item.name === 'id')?.value === parentId
      )
      dataClone.splice(parentIndex + 1, 0, waitingLine)
    }
  }

  return dataClone
}

export function decideStatusCodeWhenClickEnvoyerEnMission(
  detailDataMapping: ComArticle[],
  detailLineDeletedIds: string[]
): StatusCommande | undefined {
  const numberOfLinesNeedToDelete = detailLineDeletedIds.length

  const isAllCheckLotEqual0 = detailDataMapping
    .filter((item) => item.source === sourceType.MANUAL)
    .every((item) => item.ruptures_check_lot === 0)

  const isNoNeedWaiting = numberOfLinesNeedToDelete === 0 && isAllCheckLotEqual0

  if (isNoNeedWaiting) return StatusCommande.SendOnMissionCommande
  return undefined

  //return StatusCommande.OTHER
}

export function decideStatusCodeWhenClickRemetre(
  detailDataMapping: ComArticle[],
  detailDataMappingChild: ComArticle[]
) {
  const isAllCheckLotEqual0 = detailDataMapping
    .filter((item) => item.source === sourceType.MANUAL)
    .every((item) => item.ruptures_check_lot === 0)
  const allChildToPrMission =
    detailDataMappingChild.every((item) => item.is_color) &&
    detailDataMappingChild.length > 0
  if (isAllCheckLotEqual0 && allChildToPrMission)
    return StatusCommande.SendOnMissionCommande

  //return StatusCommande.OTHER
}

export function updateManquantsRightBefore_MetreOrRemetre(
  detailDataMapping: ComArticle[]
) {
  for (let i = 0; i < detailDataMapping.length; i++) {
    const line = detailDataMapping[i]

    const isParent = line.source === sourceType.MANUAL
    if (!isParent) continue

    let sscc_motif = line.sscc_motif

    let manquants = line.manquants || 0

    sscc_motif = sscc_motif.map((item) => {
      const parse = JSON.parse(item || '{}')
      if (parse.needCountForManquants) manquants++
      parse.needCountForManquants = false
      return JSON.stringify(parse)
    })

    detailDataMapping[i].manquants = manquants
    detailDataMapping[i].sscc_motif = sscc_motif
  }
  return detailDataMapping
}

export function checkExistWaitingLine(data: DataTableParam[][]): boolean {
  for (let i = 0; i < data.length; i++) {
    const currentLine = data[i]
    const id = currentLine.find((item) => item.name === 'id')?.value
    const parentId = currentLine.find(
      (item) => item.name === 'parent_id'
    )?.value
    const isWaitingLine = !id && parentId
    if (isWaitingLine) return true
  }

  return false
}

export function getCurrentRef(
  references: EntryFind[],
  currentLine: DataTableParam[]
) {
  const nom = currentLine.find((item) => item.name === 'reference')?.value
  if (!nom) return
  const currentRef = references.find((item) => item.nom === nom)
  return currentRef
}

export function checkIsQteStandard(reference: EntryFind | undefined) {
  if (!reference) return
  const isQTE =
    reference.conditionnement.length > 0 &&
    reference?.conditionnement.every((item) => Boolean(item.qte))
  return isQTE
}

export function mappingDetailData(
  detailData: DataTableParam[][],
  detailDataFromApi: DataTableParam[][],
  response?: CommandeResponse
) {
  const detailDataMapping = convertSentPreparationDataTableToAPI(
    detailData.length > 0 ? detailData : detailDataFromApi,
    response?.com_article || []
  )
  const articleResponse = response?.com_article || []
  for (let i = 0; i < detailDataMapping.length; i++) {
    const id = detailDataMapping[i].id
    const articleCorrespond = articleResponse.find((item) => item.id === id)
    detailDataMapping[i].created_by = response?.created_by
    detailDataMapping[i].creater_id = response?.creater_id
    detailDataMapping[i].modification_by =
      localStorage.getItem(Define.USERNAME) || ''
    detailDataMapping[i].modificationer_id =
      localStorage.getItem(Define.USER_ID) || ''
    detailDataMapping[i].status_code = response?.status_code
    detailDataMapping[i].is_manual_dluo = articleCorrespond?.is_manual_dluo
    detailDataMapping[i].is_manual_sscc = articleCorrespond?.is_manual_sscc
    detailDataMapping[i].is_manual_lot = articleCorrespond?.is_manual_lot
    detailDataMapping[i].is_color = true
    detailDataMapping[i].commande_id = response?.id
    detailDataMapping[i].parent_article_id =
      articleCorrespond?.parent_article_id
    detailDataMapping[i].stock_id = articleCorrespond?.stock_id || ''
    detailDataMapping[i].pre_status = articleCorrespond?.pre_status
  }
  return detailDataMapping
}

export async function fakeSearchApi(): Promise<CommandeInterface> {
  return commandeApi.getCommandeById({
    id: 'comd_cc30b0b5-375a-4629-87ef-2794def54175',
  })
}

export function getLineClass(lineData: any[]) {
  const missionId = lineData?.find((i: any) => i.name === 'mission_id')?.value
  const isColor = lineData?.find((i: any) => i.name === 'is_color')?.value
  if (missionId && Boolean(isColor)) {
    return '!bg-[#E1FFF0]'
  }
  return ''
}

const disableField = (
  name: string,
  currentLine: DataTableParam[]
): DataTableParam[] => {
  const index = getIndexes(name, currentLine)
  if (index !== -1) currentLine[index].disabled = true
  return currentLine
}

export const disabledFields = (
  names: string[],
  currentLine: DataTableParam[]
): DataTableParam[] => {
  for (let i = 0; i < names.length; i++) {
    const name = names[i]
    currentLine = disableField(name, currentLine)
  }
  return currentLine
}

export function removeColsFromHeader(names: string[], header: TitleParam[]) {
  header = header.filter((item) => !names.includes(item.name || ''))
  return header
}

function resetFieldsToDash(names: string[], line: DataTableParam[]) {
  for (let i = 0; i < line.length; i++) {
    if (names.includes(line[i].name)) line[i].value = '-'
  }
}

function addFieldsToLine(nameAndValue: NameAndValue, line: DataTableParam[]) {
  const { name, value } = nameAndValue
  line.push({ name: name || '', value })
  return line
}

function setValueToLine(nameAndValue: NameAndValue, line: DataTableParam[]) {
  const { name, value } = nameAndValue
  const index = line.findIndex((item) => item.name === name)
  if (index !== -1) line[index].value = value
  return line
}

export function addOrChangeValueInLine(
  nameAndValue: NameAndValue,
  line: DataTableParam[]
) {
  const { name } = nameAndValue
  const index = line.findIndex((item) => item.name === name)
  if (index === -1) addFieldsToLine(nameAndValue, line)
  else setValueToLine(nameAndValue, line)
  return line
}

export function resetAllFieldsToDash(
  names: string[],
  data: DataTableParam[][]
) {
  for (let i = 0; i < data.length; i++) {
    resetFieldsToDash(names, data[i])
  }
}

export function markTheResultSearchStock(
  data: DataTableParam[][]
): DataTableParam[][] {
  for (let i = 0; i < data.length; i++) {
    const line = data[i]
    const parentId = line.find((item) => item.name === 'parent_id')?.value
    if (!parentId) continue
    line.push({
      name: 'searchResult',
      value: parentId ? '1' : '0',
    })
  }

  return data
}

export function getValueFromLine(name: string, line: DataTableParam[]) {
  return line?.find((item) => item.name === name)?.value
}

export function removeAllDetailLine(
  data: DataTableParam[][],
  actions: ActionParam[]
) {
  let length = data.length
  for (let i = 0; i < length; i++) {
    const line = data[i]
    const parent_id = getValueFromLine('parent_id', line)
    if (!parent_id) continue
    data.splice(i, 1)
    actions.splice(i, 1)
    length--
    i--
  }
}

export function removeAllDetailLine2(data: DataTableParam[][]) {
  return data.filter((line) => !getValueFromLine('parent_id', line))
}

export function hasAtleastDetailLine(data: DataTableParam[][]) {
  if (data.length === 0) return false

  return data.some((line) => getValueFromLine('parent_id', line))
}

export function getIndexFieldInHeader(name: string, header: TitleParam[]) {
  return header.findIndex((itemm) => itemm.name === name)
}

export function removeFieldFromHeader(name: string, header: TitleParam[]) {
  return header.filter((item) => item.name !== name)
}

export function addFieldToHeadOfHeader(item: TitleParam, header: TitleParam[]) {
  const index = getIndexFieldInHeader(item.name || '', header)
  if (index === -1 && item.name) header.unshift(item)
}

export function mapCdnToData(
  data: DataTableParam[][],
  references: EntryFind[]
) {
  for (let i = 0; i < data.length; i++) {
    let line = data[i]
    const refNom = getValueFromLine('reference', line)
    const currentRef = references.find((item) => item.nom === refNom)
    if (!currentRef) continue

    const cndIndex = getIndexes('cdn', line)
    if (cndIndex === -1) continue
    line[cndIndex].options = currentRef.conditionnement.map((item) => {
      return {
        value: item.id,
        label: item.nom,
        extraValue: `${item.qte}`,
        level: item.niveau,
      }
    })
    console.log({ line })
    data[i] = line
  }
}

export function getLineWithSourceFromDataTable(
  comArticle: DataTableParam[][],
  sourceType: sourceType
) {
  return comArticle.filter(
    (line) => getValueFromLine('source', line) === sourceType.toString()
  )
}

export function isEqualBeetweenLine(
  line1: DataTableParam[],
  line2: DataTableParam[]
) {
  const ref1 = getValueFromLine('reference', line1)
  const lot1 = getValueFromLine('lot', line1)
  const sscc1 = getValueFromLine('sscc', line1)
  const dluo1 = getValueFromLine('dluo', line1)
  const cdn1 = getValueFromLine('cdn', line1)
  const qte1 = getValueFromLine('qte', line1)

  const ref2 = getValueFromLine('reference', line2)
  const lot2 = getValueFromLine('lot', line2)
  const sscc2 = getValueFromLine('sscc', line2)
  const dluo2 = getValueFromLine('dluo', line2)
  const cdn2 = getValueFromLine('cdn', line2)
  const qte2 = getValueFromLine('qte', line2)

  return (
    ref1 === ref2 &&
    lot1 === lot2 &&
    sscc1 === sscc2 &&
    dluo1 === dluo2 &&
    cdn1 === cdn2 &&
    qte1 === qte2
  )
}

export function isEqualBeetweenLineWithId(
  line1: DataTableParam[],
  line2: DataTableParam[]
) {
  const id1 = getValueFromLine('id', line1)
  const id2 = getValueFromLine('id', line2)

  return id1 === id2
}

export function isEqualBeetweenComArticle(com1: ComArticle, com2: ComArticle) {
  const id1 = com1.id
  const ref1 = com1.reference_id
  const lot1 = com1.lot || ''
  const sscc1 = com1.sscc || ''
  const dluo1 = com1.dluo || ''
  const cdn1 = com1.conditionement_id
  const qte1 = com1.qte_confirmee

  const id2 = com2.id
  const ref2 = com2.reference_id || ''
  const lot2 = com2.lot || ''
  const sscc2 = com2.sscc || ''
  const dluo2 = com2.dluo || ''
  const cdn2 = com2.conditionement_id
  const qte2 = com2.qte_confirmee

  return (
    ref1 === ref2 &&
    lot1 === lot2 &&
    sscc1 === sscc2 &&
    dluo1 === dluo2 &&
    cdn1 === cdn2 &&
    qte1 === qte2
  )
}

function removeFieldFromLine(name: string, line: DataTableParam[]) {
  return line.filter((item) => item.name !== name)
}

export function getNewLineNotSaveYet(data: DataTableParam[][]) {
  return data.filter((line) => !getValueFromLine('id', line))
}

export function removeIdOfLineNotSaveYet(
  data: DataTableParam[][],
  newLineNotSaveYet: DataTableParam[][]
) {
  for (let i = 0; i < data.length; i++) {
    let line = data[i]

    for (let j = 0; j < newLineNotSaveYet.length; j++) {
      const newLine = newLineNotSaveYet[j]
      if (!isEqualBeetweenLine(line, newLine)) continue
      line = removeFieldFromLine('id', line)
    }

    data[i] = line
  }

  return data
}

export function getLineDiscarded(
  orignData: DataTableParam[][],
  newData: DataTableParam[][]
) {
  let result = []

  const newDataWithIds = newData
    .filter((line) => getValueFromLine('id', line))
    .map((line) => getValueFromLine('id', line))

  result = orignData.filter(
    (line) => !newDataWithIds.includes(getValueFromLine('id', line))
  )

  return result
}

export function getLineDiscardedWithoutNewLine(
  orignData: DataTableParam[][],
  newData: DataTableParam[][]
) {
  return getLineDiscarded(orignData, newData).filter(
    (line) => getValueFromLine('isNewLine', line) !== '1'
  )
}

export function enableField(name: string, line: DataTableParam[]) {
  const index = getIndexes(name, line)
  if (index !== -1) line[index].disabled = false
}

export function mapOriginDataAndSearchData(
  originData: DataTableParam[][],
  searchData: DataTableParam[][]
) {
  const originIds = originData.map((line) => getValueFromLine('id', line))
  for (let i = 0; i < searchData.length; i++) {
    const currentSearchData = searchData[i]
    const currentSearchId = getValueFromLine('id', currentSearchData)
    const source = getValueFromLine('source', searchData[i]) as sourceType
    if (source !== sourceType.MANUAL) continue

    // const sameOrginData = originData.find(origin => isEqualBeetweenLine(origin, currentSearchData) || isEqualBeetweenLineWithId(origin, currentSearchData));
    const isSave = originIds.includes(currentSearchId)
    addOrChangeValueInLine(
      { name: 'isNewLine', value: isSave ? '0' : '1' },
      searchData[i]
    )

    // if (sameOrginData) {

    //   let idOfOriginData = getValueFromLine('id', sameOrginData);
    //   const idOfSearchData = getValueFromLine('id', searchData[i]);
    //   setValueToLine({ name: 'id', value: idOfOriginData }, searchData[i]);
    //   addOrChangeValueInLine({ name: 'isNewLine', value: '0' }, searchData[i])
    //   for (let j = 0; j < searchData.length; j++) {
    //     const source = getValueFromLine('source', searchData[j]) as sourceType;
    //     if (source !== sourceType.AUTO) continue;
    //     const parentId = getValueFromLine('parent_id', searchData[j]);
    //     if (parentId !== idOfSearchData) continue;
    //     setValueToLine({ name: 'parent_id', value: idOfOriginData }, searchData[j]);
    //   }
    // }
    // else addOrChangeValueInLine({name: 'isNewLine', value: '1'}, searchData[i]);
  }
  return searchData
}

export function disabledFieldWithCondition(
  callBack: (line: DataTableParam[]) => boolean,
  line: DataTableParam[],
  name: string
) {
  const resultOfCondition = callBack(line)
  if (!resultOfCondition) return

  const index = getIndexes(name, line)
  if (index === -1) return

  line[index].disabled = true
}

export function dataIsEmpty(data: DataTableParam[][]) {
  if (data.length > 1) return false
  if (data.length === 0) return true
  const referenceNom = getValueFromLine('reference', data[0])
  if (!referenceNom) return true
  return false
}

export function removeFakeId(data: DataTableParam[][]) {
  for (let i = 0; i < data.length; i++) {
    const isNewLine = getValueFromLine('isNewLine', data[i]) === '1'
    if (isNewLine) data[i] = removeFieldFromLine('id', data[i])
  }
}

export function getTotalQteConfirmOfLine(
  data: DataTableParam[][],
  parentId?: string
) {
  return (
    data.reduce((current, line) => {
      if (
        line.find((item) => item.name === 'parent_id')?.value === parentId &&
        line.find((item) => item.name === 'id')?.value
      ) {
        const qte = line?.find((item) => item.name === 'qte_confirmee')
        return current + (qte ? parseInt(qte?.value || '0') : 0)
      } else {
        return current
      }
    }, 0) || 0
  )
}

export function isNotHighestItemLevel(detailLines: DataTableParam[][]) {
  return detailLines.some(
    (line) =>
      line.find((item) => item.name === 'conditionement_level')?.value !=
      define.MAX_CDN_LEVEL.toString()
  )
}

export function getModalAndFooter() {
  const bodyOfModal = document.getElementsByClassName('ant-modal-body')
  const footerOfModal = document.getElementsByClassName('ant-modal-footer')
  if (bodyOfModal.length === 0 || footerOfModal.length === 0)
    return { modal: null, footer: null }
  const modal = bodyOfModal[0]
  const footer = footerOfModal[0]

  return { modal, footer }
}

export function disabledAllModal() {
  const { modal, footer } = getModalAndFooter()
  if (!modal || !footer) return
  modal.classList.add('disable-all-event')
  footer.classList.add('disable-all-event')
}

export function enabledAllModal() {
  const { modal, footer } = getModalAndFooter()
  if (!modal || !footer) return
  modal.classList.remove('disable-all-event')
  footer.classList.remove('disable-all-event')
}
