export enum EtaStock {
  CREATE = '0',
  CORRESPOND = '1',
  SORTI = '2',
  TMP = '3',
}

export enum StatusStock {
  CREATE = '30-0',
  CORRESPOND = '30-1',
  SORTI = '30-2',
  TMP = '30-3',
}
