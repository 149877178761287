import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import moment from 'moment'
import RobotoBold from '../../../../../assets/fonts/Roboto-Bold.ttf'
import { StatusCommande } from '../../../../../enum/StatusCommande'
import { sourceType } from '../../../../../enum/sourceType'
import { ComArticle, CommandeResponse } from '../../../../../models'
import { handleNewErrorDetail } from './func'
import { t } from 'i18next'
import { DocumentType } from '../../../../../enum/DocumentStatus'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
})

interface PickingListProps {
  /**
   * content of picking list
   */
  commande?: CommandeResponse
  /**
   * index of picking list
   */

  idWithOrder?: string
  /**
   * check if force dluo switch is turn on
   */
  isForceDluo?: boolean
  /**
   * type of picking list
   */
  pickingType?: DocumentType
  title: string
}

const styles = StyleSheet.create({
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginHorizontal: 20,
  },
  bodyTable: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    fontSize: 7,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    // alignItems: 'center',
    fontSize: 7,
  },

  header: {
    // borderTop: 'none',
  },
  bold: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  rowTableHeader: {
    width: '10%',
    padding: 8,
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  rowTable: {
    // width: '8%',

    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    // overflow: 'hidden',
  },
  rowTableNoSpace: {
    width: 40,
    padding: 8,
    paddingRight: 0,
    borderStyle: 'solid',
    borderTopWidth: 1,
    textAlign: 'center',
    marginRight: -1,
  },
  rowTableLast: {
    // width: '15%',
    padding: 8,
    paddingRight: 0,
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 1,
  },
  header1: {
    fontWeight: 700,
    color: '#20458F',
    fontSize: 18,
    marginRight: 10,
  },
  header2Div: {
    paddingBottom: 10,
    borderBottom: '1px solid black',
    width: 120,
    marginLeft: 20,
    marginBottom: 10,
  },
  header2: {
    fontSize: 10,
    marginTop: 10,
  },
  header2Padding: {
    fontSize: 10,
    marginBottom: 5,
    marginLeft: 20,
  },
  header3: {
    color: '#20458F',
    fontSize: 18,
  },
  row1: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #B7B7B7',
    marginBottom: 10,
    padding: 10,
  },

  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  COMMENTAIRE: {
    border: '1px solid black',
    fontSize: 10,
    fontWeight: 400,
    padding: '5px 10px',
    marginRight: 20,
    flex: 3,
  },
})

export function addBreakLine(
  text: string,
  numberOfDigitsPerLine: number
): string {
  let result = ''
  const length = text.length
  const numberOfPart = length / numberOfDigitsPerLine
  const textArr = text.split('')
  for (let i = 0; i < numberOfPart; i++) {
    const currentPart = []
    for (
      let j = i * numberOfDigitsPerLine;
      j < (i + 1) * numberOfDigitsPerLine;
      j++
    ) {
      currentPart[j] = textArr[j]
    }
    let currentPartString = currentPart.join('')
    currentPartString += '\n'
    result += currentPartString
  }

  return result
}

const handleForceDluo = (
  commande: CommandeResponse | undefined,
  com_article: false | ComArticle[] | undefined
) => {
  const defaultForceDluo = commande?.force_dlou
  if (defaultForceDluo) return defaultForceDluo

  const description = (com_article || []).map((item) => item.description)
  for (let i = 0; i < description.length; i++) {
    const currentDes = description[i]
    if (currentDes?.includes('DLUO was expired but setting force dluo'))
      return true
  }
  return false
}

/**
 * The @PickingList is a component display file commande Picking list
 * @param props
 * @returns
 */
const PickingList = (props: PickingListProps) => {
  const { commande, pickingType, title } = props
  const com_article =
    commande?.com_article && commande?.com_article?.length > 0
      ? commande?.com_article?.filter(
          (item) =>
            item.status_code &&
            (([
              StatusCommande.AssignedCommande,
              StatusCommande.InProgressCommande,

              StatusCommande.PickupCommande,
              StatusCommande.MissionUnassigned,
              StatusCommande.MissionAssigned,
              StatusCommande.MissionInprogress,
              StatusCommande.MissionPending,
              StatusCommande.MissionDone,
            ].includes(item.status_code) &&
              item.source === sourceType.MANUAL) ||
              ([
                StatusCommande.SendOnMissionCommande,
                StatusCommande.ValidCommande,
              ].includes(item.status_code) &&
                item.source === sourceType.AUTO))
        )
      : []
  const hasArticlePickingIncludeSSCC =
    pickingType === DocumentType.Carton &&
    com_article?.find((c) => c.sscc && c.is_manual_sscc)

  return (
    <Document>
      <Page orientation="landscape" size="A4">
        <View>
          <View style={[styles.row1]}>
            <Text style={styles.header1}>{title}</Text>
            <Text style={styles.header3}>(n°{props?.idWithOrder})</Text>
          </View>
          <View>
            <View style={styles.header2Div}>
              <Text style={styles.header2}>{title.toUpperCase()}</Text>
            </View>

            <View style={styles.flexRow}>
              <View style={{ flex: 2 }}>
                <View style={[styles.header2Padding, { paddingBottom: 10 }]}>
                  <Text style={styles.header2}>
                    B.L. : {commande?.nom || ''}
                  </Text>
                </View>
                <Text style={styles.header2Padding}>
                  Editée le{' '}
                  {moment(Number(commande?.updated_at || 0) * 1000).format(
                    t('date-format')
                  )}{' '}
                  à{' '}
                  {moment(Number(commande?.updated_at || 0) * 1000).format(
                    'HH:mm'
                  )}
                </Text>
                <Text style={styles.header2Padding}>
                  CLIENT : {commande?.client_code_nom}
                </Text>

                <Text style={styles.header2Padding}>
                  Forçage DLUO :{' '}
                  {handleForceDluo(commande, com_article) ? 'Oui' : 'Non'}
                </Text>
              </View>

              <View style={styles.COMMENTAIRE}>
                <Text>COMMENTAIRE DE PREPARATION :</Text>
                <Text style={{ textTransform: 'uppercase' }}>
                  {commande?.com_preparation?.comment}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ height: 20 }}></View>
        <View style={styles.table}>
          <View style={[styles.row, styles.header]}>
            <Text style={[styles.rowTable, styles.bold, { width: 100 }]}>
              REFERENCE
            </Text>
            <Text style={[styles.rowTable, styles.bold, { width: 130 }]}>
              LOT
            </Text>

            <Text
              style={[
                styles.rowTable,
                styles.bold,
                { width: pickingType === DocumentType.Pallete ? 140 : 70 },
              ]}
            >
              {pickingType === DocumentType.Pallete ? 'SSCC' : 'Cdn'}
            </Text>
            {hasArticlePickingIncludeSSCC && (
              <Text style={[styles.rowTable, styles.bold, { width: 140 }]}>
                SSCC
              </Text>
            )}
            <Text style={[styles.rowTable, styles.bold, { width: 70 }]}>
              DLUO
            </Text>
            <Text style={[styles.rowTable, styles.bold, { width: 70 }]}>
              QUANTITE
            </Text>
            <Text style={[styles.rowTable, styles.bold, { width: 80 }]}>
              TOTAL PIECE
            </Text>
            <Text
              style={[
                styles.rowTable,
                styles.bold,
                { width: 50, paddingLeft: 10 },
              ]}
            >
              (ZONE
            </Text>
            <Text style={[styles.rowTableNoSpace, styles.bold, { width: 50 }]}>
              / ALLEE
            </Text>
            <Text style={[styles.rowTableNoSpace, styles.bold, { width: 60 }]}>
              / TRAVEE
            </Text>
            <Text style={[styles.rowTableNoSpace, styles.bold, { width: 60 }]}>
              / NIVEAU
            </Text>
            <Text
              style={[
                styles.rowTableNoSpace,
                styles.bold,
                { width: 80, borderRight: '1px solid black' },
              ]}
            >
              / POSITION)
            </Text>
            <Text
              style={[
                styles.rowTableLast,
                styles.bold,
                { paddingLeft: 10, width: 180 },
              ]}
            >
              NOTE
            </Text>
          </View>
          {(com_article || [])?.map((el, index) => (
            <View
              key={index}
              style={[
                styles.bodyTable,
                index === ((com_article || [])?.length || 0) - 1
                  ? { borderBottomWidth: 1 }
                  : {},
              ]}
              wrap={false}
            >
              <Text style={[styles.rowTable, { width: 100 }]}>
                <Text>{addBreakLine(el.reference_number || '', 10)}</Text>
              </Text>
              <Text
                style={[
                  styles.rowTable,
                  el.is_manual_lot ? styles.bold : {},
                  { width: 130 },
                ]}
              >
                {addBreakLine(el.lot || '-', 10)}
              </Text>

              <Text
                style={[
                  styles.rowTable,
                  { width: pickingType === DocumentType.Pallete ? 140 : 70 },
                ]}
              >
                {(pickingType === DocumentType.Pallete
                  ? el.sscc
                  : el.conditionnement?.nom) || ' '}
              </Text>
              {hasArticlePickingIncludeSSCC && (
                <Text
                  style={[
                    styles.rowTable,
                    el.is_manual_sscc ? styles.bold : {},
                    { width: 140 },
                  ]}
                >
                  {el.is_manual_sscc && el.sscc ? el.sscc : ''}
                </Text>
              )}
              <Text
                style={[
                  styles.rowTable,
                  el.is_manual_dluo ? styles.bold : {},
                  { width: 70 },
                ]}
              >
                {el.dluo
                  ? moment(Number(el.dluo) * 1000).format(t('date-format'))
                  : '-'}
              </Text>
              <Text style={[styles.rowTable, { width: 70 }]}>
                {el.quantite || 0}
              </Text>
              <Text style={[styles.rowTable, { width: 80 }]}>
                {el.total_pieses}
              </Text>
              <Text style={[styles.rowTable, { width: 50 }]}>
                {el.stock?.cur_zone_code || ' '}
              </Text>
              <Text style={[styles.rowTableNoSpace, { width: 50 }]}>
                {el.stock?.cur_location_aisle || ' '}
              </Text>
              <Text style={[styles.rowTableNoSpace, { width: 60 }]}>
                {el.stock?.cur_location_bay || ' '}
              </Text>
              <Text style={[styles.rowTableNoSpace, { width: 60 }]}>
                {el.stock?.cur_level === -1 ? '0' : el.stock?.cur_level || ' '}
              </Text>
              <Text style={[styles.rowTableNoSpace, { width: 80 }]}>
                {el.stock?.cur_location_code === '00'
                  ? '0'
                  : el.stock?.cur_location_code.charAt(0) || ' '}
              </Text>
              <Text
                style={[
                  styles.rowTableLast,
                  styles.bold,
                  { width: 180, borderLeft: '1px solid black' },
                ]}
              >
                {handleNewErrorDetail(el.description || '')}
              </Text>
            </View>
          ))}

          {(!commande?.com_article ||
            (commande?.com_article && commande?.com_article?.length === 0)) && (
            <View>
              <View style={[styles.bodyTable]} wrap={false}>
                <Text style={[styles.rowTable, { width: 80 }]}>
                  <Text></Text>
                </Text>
                <Text style={[styles.rowTable, { width: 65 }]}></Text>
                <Text style={[styles.rowTable, { width: 120 }]}></Text>
                <Text style={[styles.rowTable, { width: 80 }]}></Text>
                <Text style={[styles.rowTable, { width: 80 }]}></Text>
                <Text style={[styles.rowTable, { width: 80 }]}></Text>
                <Text style={[styles.rowTable, { width: 90, paddingRight: 0 }]}>
                  {' '}
                </Text>
                <Text style={[styles.rowTableNoSpace, { width: 50 }]}></Text>
                <Text style={[styles.rowTableNoSpace, { width: 50 }]}></Text>
                <Text style={[styles.rowTableNoSpace, { width: 60 }]}></Text>
                <Text style={[styles.rowTableNoSpace, { width: 60 }]}></Text>
                <Text style={[styles.rowTableNoSpace, { width: 80 }]}></Text>
                <Text
                  style={[
                    styles.rowTableLast,
                    styles.bold,
                    { width: 150, borderLeft: '1px solid black' },
                  ]}
                ></Text>
              </View>
              <View style={{ height: 1, backgroundColor: 'black' }}></View>
            </View>
          )}
        </View>
      </Page>
    </Document>
  )
}

export default PickingList
