import { pdf } from '@react-pdf/renderer'
import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import Define from '../../../../../constants/define'
import {
  DocumentStatus,
  DocumentType,
} from '../../../../../enum/DocumentStatus'
import { StatusCommande } from '../../../../../enum/StatusCommande'
import { sourceType } from '../../../../../enum/sourceType'
import commandeApi from '../../../../../http/commandeApi'
import { ComArticle, CommandeResponse } from '../../../../../models'
import { FileDescription } from '../../../../../models/Mission'
import PickingList from './PickingList'

export async function uploadPalletePickList(
  response: CommandeResponse,
  statut: DocumentStatus
) {
  const commandeResponse = cloneDeep(response)
  let numberOfPickingList = 0

  commandeResponse.com_file
    ?.filter((item) => item.source === sourceType.AUTO)
    .forEach((file) => {
      const fileName = file.name
      const type = file.description ? JSON.parse(file.description).type : ''
      if (
        fileName.includes(t('articleFileTitle')) &&
        type === DocumentType.Pallete
      )
        numberOfPickingList++
    })
  numberOfPickingList += 1

  const comPalleteArticle = commandeResponse.com_article?.filter(
    (el) =>
      el.status_code &&
      [
        StatusCommande.SendOnMissionCommande,
        StatusCommande.ValidCommande,
      ].includes(el.status_code) &&
      el.sscc &&
      !el.is_color &&
      el.source === sourceType.AUTO &&
      el.conditionnement?.niveau === Define.MAX_CDN_LEVEL &&
      !el.info_action
  )
  if (comPalleteArticle?.length) {
    commandeResponse.com_article = comPalleteArticle
    let blobPdf = await pdf(
      PickingList({
        commande: commandeResponse,
        idWithOrder: `${commandeResponse.nom}-${numberOfPickingList}`,
        pickingType: DocumentType.Pallete,
        title: t('articleFileTitle'),
      })
    ).toBlob()
    let fileName =
      `Liste de prélèvement (n०${commandeResponse.nom}-${numberOfPickingList}).pdf` ||
      ''
    let file = new File([blobPdf], fileName)
    const description: FileDescription = {
      fileName: `${commandeResponse.nom}-${numberOfPickingList}`,
      displayName: fileName,
      statut,
      type: DocumentType.Pallete,
    }
    if (statut === DocumentStatus.Envoye)
      description.sendMissionPrAt = new Date().getTime()

    await commandeApi.autoUploadFile({
      file,
      commande_id: commandeResponse.id || '',
      description: JSON.stringify(description),
      created_at: commandeResponse.updated_at,
    })
  }
}

export async function uploadCartonPickList(
  response: CommandeResponse,
  statut: DocumentStatus
) {
  let numberOfPickingList = 0
  const commandeResponse = cloneDeep(response)
  commandeResponse.com_file
    ?.filter((item) => item.source === sourceType.AUTO)
    .forEach((file) => {
      const fileName = file.name
      const type = file.description ? JSON.parse(file.description).type : ''
      if (
        fileName.includes(t('articlePickingFileTitle')) &&
        type === DocumentType.Carton
      )
        numberOfPickingList++
    })
  numberOfPickingList += 1
  let comCartonArticle = commandeResponse.com_article?.filter(
    (el) =>
      el.status_code &&
      [
        StatusCommande.SendOnMissionCommande,
        StatusCommande.ValidCommande,
      ].includes(el.status_code) &&
      el.source === sourceType.AUTO &&
      !el.is_color &&
      el.conditionnement?.niveau !== Define.MAX_CDN_LEVEL &&
      el.parent_article_id
  )
  if (comCartonArticle?.length) {
    console.log(comCartonArticle)
    commandeResponse.com_article = comCartonArticle.reduce(
      (list: ComArticle[], item) => {
        const {
          lot,
          reference_id,
          dluo,
          stock,
          is_manual_sscc,
          is_manual_lot,
          is_manual_dluo,
        } = item
        const existedRefLotDetail = list.find(
          (i) =>
            i.lot === lot &&
            i.reference_id === reference_id &&
            i.dluo === dluo &&
            i.is_manual_lot === is_manual_lot &&
            i.is_manual_dluo === is_manual_dluo &&
            i.stock?.cur_location_id === stock?.cur_location_id &&
            !is_manual_sscc
        )
        if (existedRefLotDetail) {
          existedRefLotDetail.total_pieses =
            item.total_pieses + existedRefLotDetail.total_pieses
          existedRefLotDetail.quantite =
            (item.quantite || 0) + (existedRefLotDetail.quantite || 0)
          return list
        } else {
          return [...list, item]
        }
      },
      []
    )
    let blobPdf = await pdf(
      PickingList({
        commande: commandeResponse,
        idWithOrder: `${commandeResponse.nom}-${numberOfPickingList}`,
        pickingType: DocumentType.Carton,
        title: t('articlePickingFileTitle'),
      })
    ).toBlob()
    let fileName =
      `Liste de prélèvement picking (n०${commandeResponse.nom}-${numberOfPickingList}).pdf` ||
      ''

    let file = new File([blobPdf], fileName)
    const description: FileDescription = {
      fileName: `${commandeResponse.nom}-${numberOfPickingList}`,
      displayName: fileName,
      statut,
      type: DocumentType.Carton,
    }
    if (statut === DocumentStatus.Envoye)
      description.sendMissionPrAt = new Date().getTime()
    await commandeApi.autoUploadFile({
      file,
      commande_id: commandeResponse.id || '',
      description: JSON.stringify(description),
      created_at: commandeResponse.updated_at,
    })
  }
}
